/**
 * Start - React
 * Name and Version: react, 16.9.0
 * Download link: https://www.npmjs.com/package/react
 */
import React, { Component } from 'react';
/* End - React */
/**
 * Start - React Bootstrap
 * Name and Version: react-bootstrap, 1.0.0-beta.12
 * Download link: https://www.npmjs.com/package/react-bootstrap
 */
import { Dropdown, Table, Media, Image, OverlayTrigger, Tooltip, Modal, Button,Row,Col } from 'react-bootstrap';
/* End - React Bootstrap */
import IndividualGroup from '../chat/individual-group';
import * as Images from '../common/images';
import { Messages } from '../common/message';
import * as Enum from '../common/enum';
import * as Helper from '../common/functions';
import {ModalBox, ErrorModal, ErrorAccessDeniedModal} from "../modal/modalbox";
import * as Storage from "../common/storage";
import Loader from "../loader/loader";
class ContactList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal:false,
            showError:false,
            showAccessDeniedError: false,
            errorMessage: null,
            loading:false,
            checkedTeams: new Map(),
            selectedTeams: new Map(),
            clubId: Storage.Get('user').selectedClubId,
            allTeams:[],
            kidId:'',
            showMsg:false
        }

    }

    //Hide the team list modal
    hideKidTeamList(){
        this.setState({  showModal: false, loading:false, showMsg:false });
    }

    /**
     * Function to show th list of kid teams
     * @param kidId
     */

    showKidTeamList(kidId){
        this.setState({  showModal: true, kidId, loading:true });
        this.state.selectedTeams.clear();
        let me = this;

        Helper.axiosInstance()
            .get(`user/teams/${me.state.clubId}/${kidId}`)
            .then((response) => {
                if (response) {
                    me.setState({
                        allTeams: response.data.teams,
                        teamMemberOf: response.data.team_member_of,
                        loading: false,
                        showMsg: response.data.team_member_of.length > 0 ? false: true
                    }, () => {
                        let selectedTeams = new Map();
                        for (let team of me.state.teamMemberOf.values()) {
                            selectedTeams.set(team, true);
                        }
                        this.setState({selectedTeams});
                    });
                }
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    me.setState({ errorMessage: error.response.data.error, showAccessDeniedError: true, loading: false, showModal:false  });
                }
            });
    }

    /**
     * Function ot set the teamId on check box change
     * @param teamId
     */
    onCheckboxChange(teamId =null){
        let selectedTeams = this.state.selectedTeams;
        if (teamId && !selectedTeams.get(teamId)) {
            selectedTeams.set(teamId, true);
        }else if(teamId >0){
            selectedTeams.delete(teamId);
        }
        if(parseInt(selectedTeams.size) < 1){
            this.setState({selectedTeams, showMsg: true});
        }else {
            this.setState({selectedTeams,showMsg:false});
        }

        //this.setState({selectedTeams});
    }

    /**
     * Function to update the kids teams list
     * @param kidId
     * @returns {boolean}
     */
    updateKidTeams(kidId){
        let teamIds = [];
        for (let val of this.state.selectedTeams.keys()) {
            teamIds.push(val);
        }
        this.setState({  loading: true});
        if (teamIds.length === 0) {
            this.deactivateContact(kidId, this.state.clubId);
           // this.setState({ errorMessage: Messages.NO_MEMBER_GROUP_SELECTED, showError: true, loading: false, showModal:true  });
            return false;
        }
        let me = this;
        Helper.axiosInstance().post(`user/update-teams`, {
            club_id: me.state.clubId,
            kid_id: kidId,
            team_id: teamIds
        })
        .then((response) => {
            // If server response message same as Data Matched
            if (response.data.success) {
                this.props.getContactList();
                this.props.filterContactList(kidId, true);
                me.setState({  loading:false, showModal: false  });
            } else {
                me.setState({ errorMessage: Messages.NO_MEMBER_GROUP_SELECTED, showError: true, loading: false, showModal:true  });
            }
        })
        .catch((error) => {
            console.log(error)
            if (error.response.status === 401) {
                me.setState({ errorMessage: error.response.data.error, showAccessDeniedError: true, loading: false, showModal:false  });
            }
        });
    }

    deactivateContact(memberId, clubId){
        Helper.axiosInstance()
            .put(`user/deactivate`, {
                club_id: clubId,
                member_id: [memberId],
            })
            .then((response) => {
                if (response.data.success === true) {
                    this.props.filterContactList(memberId, false);
                    this.setState({ loading:false, showModal: false });
                }
                this.setState({ modalLoader: false });
            })
            .catch((error) => {
                console.log(error)
                this.showError(Messages.SERVER_ERROR);
                this.setState({ modalLoader: false });
            });
    }

    render() {
       const modalHeader = (
            <>
                <Modal.Title>Update Member Group Status</Modal.Title>
            </>
        );
        const modalContent = (
            <>
                <Modal.Body>
                    <Row>
                        <Col sm={12}>
                            <p>Please select the checkbox to update the member group status.</p>
                        </Col>
                            <ul className="member-group-listing">
                        {
                            this.state.allTeams.map((item, key) => {

                                return (

                                            <li key={key}>
                                                <span className="checkwrap">
                                                    <input className="checkwrap__cbx"
                                                           checked={this.state.selectedTeams.get(item.id) ? true : false}
                                                               //this.state.selectedTeams.get(item.id) ? true : false}
                                                           onChange={()=>this.onCheckboxChange(item.id)}
                                                            id={'chk' + item.id}
                                                           value={item.id}
                                                           type="checkbox" />
                                                    <label className="checkwrap__label" htmlFor={'chk' + item.id} >
                                                        <span className="checkwrap__label_chk">
                                                            <svg width="14px" height="12px" viewBox="0 0 14 12">
                                                            <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                                            </svg>
                                                        </span>
                                                    </label>
                                                </span>
                                                <em>{item.name} {item.location ? `- ${item.location}` : ''}</em>
                                            </li>

                                )
                            })

                        }
                            </ul>

                    </Row>
                    {this.state.showMsg ?
                    <Row>
                        <Col sm="12">
                            <div className="alert alert-warning mt-3">
                                <strong>Warning: </strong>
                                <p className="mb-0 ml-1">This user will be deactivated if not a member of any group.</p>
                            </div>
                        </Col>
                    </Row>
                        :''
                    }
                </Modal.Body>

                <Modal.Footer className="m-footer mt-0">
                    <div className="m-footer__btnwrap m-footer__btnwrap--lg-btn">
                        <Button variant="secondary" onClick={()=>this.hideKidTeamList()}>
                            Cancel
                        </Button>
                        <Button type="Submit" variant="secondary" className="ripple btn btn-primary btn-medium" onClick={()=>this.updateKidTeams(this.state.kidId)}>
                            Update
                        </Button>
                    </div>
                </Modal.Footer>
            </>
        );
       let props = this.props;
        let sortImage = Images.ArrowSort;
        if(typeof props.sortCriteria != 'undefined' && props.sortCriteria.direction == 'desc'){
            sortImage = Images.ArrowDown;
        } else if(typeof props.sortCriteria != 'undefined' && props.sortCriteria.direction == 'asc') {
            sortImage = Images.ArrowUp;
        }

       let showGuardianColumn = Helper.isCoachOrAdminLogin();

        if (props.selectedTab !== undefined && props.selectedTab) {
            showGuardianColumn = (Helper.isCoachOrAdminLogin() && !(props.selectedTab === Enum.UserRoles.LEGAL_GUARDIAN || props.selectedTab === Enum.UserRoles.COACH))
        }

        if (props.data != null) {
            return (<>
                    <Loader loading={this.state.loading} />
                    <Table bordered hover className="table-contact tab-responsive table-responsive">
                <thead>
                    <tr>
                        <th>

                        </th>
                        {
                            typeof props.sortCriteria != 'undefined' ?
                                <th className={showGuardianColumn ? 'custom-width-column col-sortable' : 'col-sortable'} onClick={() => props.sortHandler('first_name')}>First Name
                                { props.sortCriteria.column == 'first_name' ?
                                    <Image src={sortImage} alt="Sort Direction" className="ml-2"/> :
                                    <Image src={Images.ArrowSort} alt="Sort Direction" className="ml-2"/>
                                }</th>
                            : <th>First Name</th>
                        }
                        {
                            typeof props.sortCriteria != 'undefined' ?
                                <th className={showGuardianColumn ? 'custom-width-column col-sortable' : 'col-sortable'}  onClick={() => props.sortHandler('last_name')}>Last Name
                                { props.sortCriteria.column == 'last_name' ?
                                    <Image src={sortImage} alt="Sort Direction" className="ml-2"/> :
                                    <Image src={Images.ArrowSort} alt="Sort Direction" className="ml-2"/>
                                }</th>
                            : <th>Last Name</th>
                        }

                        {
                            Helper.isAdminLogin() && <th>Username</th>
                        }

                        {showGuardianColumn && <th> {Enum.LegalGuardian} </th>}
                        {
                            typeof props.sortCriteria != 'undefined' ?
                                <th className={showGuardianColumn ? 'custom-width-column col-sortable date_created-column date_created-column--detail' : 'col-sortable'} onClick={() => props.sortHandler('created_at')}>Date Created
                                { props.sortCriteria.column == 'created_at' ?
                                    <Image src={sortImage} alt="Sort Direction" className="ml-2"/> :
                                    <Image src={Images.ArrowSort} alt="Sort Direction" className="ml-2"/>
                                }</th>
                            : <th>Date Created</th>
                        }
                        {props.screen != "Member" ?
                            <th> Member Groups</th>: null
                        }
                        <th></th>

                    </tr>
                </thead>
                <tbody>
                    <tr className="mb-table-row">

                            {/* {

                                ( props.screen === "Contact" || props.screen === "props.screen === \"Contact\" " && props.data.length > 0) &&
                                <td data-label="Select All">
                                    <span className="checkwrap">
                                        <input className="checkwrap__cbx" id="checkedallmobile"
                                            checked={props.checkedAll ? true : false}
                                            type="checkbox" onChange={() => props.handleAllChecked()} />
                                        <label className="checkwrap__label" htmlFor="checkedallmobile">
                                            <span className="checkwrap__label_chk">
                                                <svg width="14px" height="12px" viewBox="0 0 14 12">
                                                    <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                                </svg>
                                            </span>
                                        </label>
                                    </span>
                                </td>

                            }	 */}

                    </tr>
                    {
                        props.data.map((item, key) => {
                            return (
                                <tr key={key}>
                                    {
                                        <td>
                                             { (props.fiterByStatus !== undefined)  ?
                                            <span className="checkwrap">
                                                <input className="checkwrap__cbx" id={key} type="checkbox"
                                                    checked={props.checkedItems.get(item.id) ? true : false}
                                                    onChange={() => props.onCheckboxChange(item)} />
                                                <label className="checkwrap__label" htmlFor={key}>
                                                    <span className="checkwrap__label_chk">
                                                        <svg width="14px" height="12px" viewBox="0 0 14 12">
                                                            <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                                        </svg>
                                                    </span>
                                                </label>
                                            </span>
                                           : ''}
                                        </td>
                                    }
                                    {item.role_id.includes(Enum.UserRoles.ATHLETE) && Helper.isCoachOrAdminLogin() && props.fiterByStatus >0 ?
                                    <td data-label="First Name" onClick={()=>this.showKidTeamList(item.id)} className={showGuardianColumn ? 'td-content-space' : 'td-content-space first-column--width'}>
                                        <span className="first-name-link">{item.first_name}</span>
                                    </td>
                                        :
                                        <td data-label="First Name"  className={showGuardianColumn ? 'td-content-space' : 'td-content-space first-column--width'}>
                                            <span>{item.first_name}</span>
                                        </td>
                                    }
                                    <td data-label="Last Name" className={showGuardianColumn ? 'td-content-space custom-width-column' : 'td-content-space first-column--width'}>
                                        {item.last_name}
                                    </td>
                                    {
                                        Helper.isAdminLogin() && <td data-label="Username" className={showGuardianColumn ? 'td-content-space custom-width-column' : 'td-content-space first-column--width'}>
                                            {item.username}
                                        </td>
                                    }

                                    {
                                        showGuardianColumn &&
                                        <td data-label="Legal Guardian" className="td-content-space">
                                            <ContactGuardian data={item.guardian} />
                                        </td>
                                    }
                                    <td data-label="Date Created" className={showGuardianColumn ? 'td-content-space custom-width-column' : 'td-content-space custom-width-column'}>
                                        {item.created_at}
                                    </td>
                                    { props.screen != "Member" ?
                                    <td data-label="Member Groups" className={showGuardianColumn ? '' : 'column--width'}>
                                        <ContactTeam data={item.team} />
                                    </td>
                                    :null }
                                    <td>
                                        <ContactUserTypeBadge data={item.role_id} />
                                        {props.screen !== 'Add Member' &&
                                            <Dropdown className="more-actions">
                                                <Dropdown.Toggle >
                                                    <span className="more-im"></span>
                                                </Dropdown.Toggle>
                                                <ContactAction
                                                    item={item}
                                                    onDeactivateContactClick={(item) => props.onDeactivateContactClick(item)}
                                                    onRemoveSubAdminClick={(item) => props.onRemoveSubAdminClick(item)}
                                                    onResetPasswordClick={(item) => props.onResetPasswordClick(item)}
                                                    onRemoveInstructorClick={(item) => props.onRemoveInstructorClick(item)}
                                                    screen={props.screen}
                                                    toggleLoader={props.toggleLoader}
                                                    allProps={this.props.allProps}
                                                />
                                            </Dropdown>
                                        }
                                    </td></tr>
                            )
                        })
                    }
                </tbody>
            </Table>
                    <ModalBox
                        size="lg"
                        show={this.state.showModal}
                        onHide={()=>this.hideKidTeamList()}
                        animation={true}
                        modalheader={modalHeader}
                        modalcontent={modalContent}
                    />
                    <ErrorModal
                        show={this.state.showError}
                        message={this.state.errorMessage}
                        onConfirmClick={() => { this.setState({ showError: false }); }}
                    ></ErrorModal>
                    <ErrorAccessDeniedModal
                        show={this.state.showAccessDeniedError}
                        message={this.state.errorMessage}
                        currentProps={this.props.allProps}
                    />
                </>
                )
        }
        else return "";
    }

}
function ContactAction(props) {
    let item = props.item;
    return (
        <>
            <Dropdown.Menu>
                <IndividualGroup
                    member={{ id: item.id, username: item.username, name: item.first_name, guardian: item.guardian, status: item.status }}
                    toggleLoader={props.toggleLoader}
                    superProps={props.allProps}

                />
                {/* Html below will be rendered for Contacts Screen */}
                {
                    /* Deactivate/Activate should be visible for athletes and valid admin login only */
                    props.screen === "Contact" &&
                    (
                        (item.role_id.includes(Enum.UserRoles.ATHLETE) && Helper.isCoachOrAdminLogin()) ||
                        ((item.role_id.includes(Enum.UserRoles.COACH) || item.role_id.includes(Enum.UserRoles.CLUB_SUB_ADMIN)) && Helper.isAdminLogin()) ||
                        (item.role_id.includes(Enum.UserRoles.COACH) && Helper.isAdminOrSubAdmin())
                    )
                    &&
                    <Dropdown.Item onClick={() => props.onDeactivateContactClick(item)}>
                        {
                            item.status ? <>
                                <Image className="mr-2" src={Images.DeleteIc} alt="Delete" width="21" />
                                <span className='de-color'>Deactivate</span> </>
                                : <><Image className="mr-2" src={Images.ActivateIcon} alt="Activate" width="21" />
                                    <span className='Activate'>Activate</span></>
                        }
                    </Dropdown.Item>
                }
                {

                    /* Remove Instructor role by admin or sub-admin*/
                    props.screen === "Contact" && Helper.isAdminOrSubAdmin() && item.role_id.includes(Enum.UserRoles.COACH) && item.role_id.includes(Enum.UserRoles.LEGAL_GUARDIAN) && item.status &&
                    <Dropdown.Item onClick={() => props.onRemoveInstructorClick(item)}>
                        <Image className="mr-2" src={Images.DeleteIc} alt="Remove" width="21" />
                        <span className='de-color'>Remove Instructor</span>
                    </Dropdown.Item>
                }
                {
                    /* Add Organisation Sub Admin should be only visible for Organisation Admin and only instructors can be assigned as Sub-Admin */
                    props.screen === "Contact" && (item.role_id.includes(Enum.UserRoles.COACH) && !item.role_id.includes(Enum.UserRoles.CLUB_SUB_ADMIN) && Helper.isAdminLogin()) && item.status >0 &&
                    <Dropdown.Item onClick={() => props.onRemoveSubAdminClick(item)}>
                        <Image className="mr-2" src={Images.ActivateIcon} alt="Assign role" width="21" />
                        <span className='Activate'> Assign Sub Admin Role</span>
                    </Dropdown.Item>
                }

                {
                    /* Remove Organisation Sub Admin should be only visible for Organisation Admin*/
                    props.screen === "Contact" && (item.role_id.includes(Enum.UserRoles.CLUB_SUB_ADMIN) && Helper.isAdminLogin()) && item.status >0 &&
                    <Dropdown.Item onClick={() => props.onRemoveSubAdminClick(item)}>
                        <Image className="mr-2" src={Images.DeleteIc} alt="Remove" width="21" />
                        <span className='de-color'>Remove Sub Admin Role</span>
                    </Dropdown.Item>
                }

                {
                    /* Remove Organisation Sub Admin should be only visible for Organisation Admin*/
                    props.screen === "Contact" && Helper.isAdminLogin() && item.status ?
                    <Dropdown.Item onClick={() => props.onResetPasswordClick(item)}>
                        <Image className="mr-2" src={Images.resetPassword} alt="Reset Password" width="21" />
                        <span className='Activate'>Reset Password</span>
                    </Dropdown.Item> : null
                }
                {/* Html below will be rendered for Team Detail Screen */}
                {
                    /* Deactivate/Activate should be visible for athletes and valid admin login only */
                    /* Coach can be removed by club admin/super admin */
                    props.screen === "Member"
                    && ((item.role_id.includes(Enum.UserRoles.ATHLETE) && Helper.isCoachOrAdminLogin()) ||
                        (item.role_id.includes(Enum.UserRoles.COACH) && Helper.isAdminOrSubAdmin()))
                    && <Dropdown.Item onClick={() => props.onDeactivateContactClick(item)}>
                        <Image className="mr-2" src={Images.DeleteIc} alt="Remove" width="21" />
                        <span className='de-color'>Remove from Group</span>
                    </Dropdown.Item>
                }
            </Dropdown.Menu>
        </>
    )
}
function ContactGuardian(props) {
    if (props.data !== null && props.data !== undefined) {
        return (
            props.data.map((guardianItem, guradianKey) => {
                return (
                    <Media key={guradianKey} className="inline-flex">
                        <div className="im-rounded-outer">
                            <Image
                                src={guardianItem.photo}
                                alt={guardianItem.first_name}
                            />
                        </div>
                        <Media.Body className="align-self-center">
                            <h5>{guardianItem.first_name}</h5>
                        </Media.Body>
                    </Media>
                )
            })
        );
    }
    else {
        return "";
    }
}
function ContactTeam(props) {
    if (props.data !== null && props.data !== undefined) {
        return (props.data.map((teamitem, teamkey) => {
            return (
                <Media key={teamkey} className="media--mrgn">
                    <Media.Body className="align-self-center">
                        <h5>{teamitem.name}</h5>
                    </Media.Body>
                </Media>
            )
        })
        )
    }
    else {
        return "";
    }
}
function ContactUser(props) {
    if (props.data !== null && props.data !== undefined) {
        return (
            <Media>
                <div className="im-rounded-outer">
                    <Image
                        src={props.data.photo}
                        alt={Helper.getMemberName(props.data)}
                    />
                </div>
                <Media.Body className="align-self-center">
                    <h5>{Helper.getMemberName(props.data)}</h5>
                </Media.Body>
            </Media>
        );
    }
    else {
        return "";
    }
}
function ContactUserTypeBadge(props) {
    if (props.data !== null && props.data !== undefined) {
        return (
            props.data.map((item, key) => {
                let badgeName = Enum.UserRolesDisplay[item];
                let className = "t-badge"
                if (item === Enum.UserRoles.COACH) {
                    className = `${className} t-badge--coach`;
                }
                else if (item === Enum.UserRoles.LEGAL_GUARDIAN) {
                    className = `${className} t-badge--lg`;
                    badgeName = Messages.LG_SHORT
                }
                else if (item === Enum.UserRoles.CLUB_SUB_ADMIN) {
                    className = `${className} t-badge--admin`;
                    badgeName = Messages.ORGSA_SHORT
                }
                else if (item === Enum.UserRoles.CLUB_ADMIN) {
                    className = `${className} t-badge--admin`;
                    badgeName = 'Organization Admin'
                }
                return (
                    <OverlayTrigger key={key}
                        overlay={
                            <Tooltip id={`tooltip`}>
                                {Enum.UserRolesDisplay[item]}
                            </Tooltip>}>
                        <div className="badge-wrap"><span className={className}>{badgeName}</span></div>
                    </OverlayTrigger>
                )
            }
            ));
    }
    else {
        return "";
    }
}

export { ContactList };
