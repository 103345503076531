/**
 * Start - React
 * Name and Version: react, 16.9.0
 * Download link: https://www.npmjs.com/package/react
 */
import React, { Component } from 'react';
/* End - React */

/**
 * Start - React Router Dom
 * Name and Version: react-router-dom, 5.0.1
 * Download link: https://www.npmjs.com/package/react-router-dom
 */
import { Link } from 'react-router-dom';
/* End - React Router Dom */

/**
 * Start - React Bootstrap
 * Name and Version: react-bootstrap, 1.0.0-beta.12
 * Download link: https://www.npmjs.com/package/react-bootstrap
 */
import {Row, Col, Image, Button, Modal} from 'react-bootstrap';
/* End - React Bootstrap */

import * as Images from '../../components/common/images';
import * as Storage from '../../components/common/storage';
import * as Helper from '../../components/common/functions';
import SingleInvite from '../../scenes/contacts/single-invite';
import { ErrorAccessDeniedModal } from '../../components/modal/modalbox';
import BroadcastMesaage from '../../components/common/broadcast-message';
import Loader from '../../components/loader/loader';
import './dashboard.scss';
import {IsrClubs} from "../isr/isr-clubs";

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showDiv: false,
            groups: null,
            isSuperAdmin: false,
            modalVisibility: false,
            total_clubs: null,
            next_invoice_date: null,
            total_athlete: null,
            total_coach: null,
            total_team: null,
            showAccessDeniedError: null,
            accessMessage: null,
            selectedMembersCount: null,
            broadcastModal:false,
            userInfo: Storage.Get('user'),
            loading: false,
            selectedClubId: Storage.Get('user') && Storage.Get('user').selectedClubId !== undefined ? Storage.Get('user').selectedClubId : null,
        };
    }

    componentDidMount = () => {
        let userInfo = Storage.Get('user');
        const isSuperAdmin = userInfo && userInfo.is_super_admin;
        this.setState({ isSuperAdmin: isSuperAdmin })
        !Helper.isISRLogin() && this.getClubDetails();
     }

    getClubDetails = () => {
         var api = !this.state.isSuperAdmin ? `club/dashboard/${this.state.selectedClubId}` : 'club/dashboard';
         (Helper.isCoachOrAdminLogin() || Storage.Get('user').is_super_admin ) ?
            Helper.axiosInstance().get(api)
                .then((response) => {
                    if (response.data.success) {
                        this.setState({
                            total_athlete: response.data.data.total_athlete,
                            total_coach: response.data.data.total_coach,
                            total_team: response.data.data.total_team,
                            pending_invites: response.data.data.pending_invites,
                            renewals: response.data.data.renewals,
                        })
                        if (this.state.isSuperAdmin) {
                            this.setState({
                                total_clubs: response.data.data.total_clubs,
                            })
                        }
                        else {
                            this.setState({ next_invoice_date: response.data.data.next_invoice_date })
                        }
                    }
                    else {
                        console.log("Error")
                    }
                })
                .catch((error) => {
                    console.log("Error1")
                })
            :
            this.setState({ accessMessage: 'You do not have access to this page.', showAccessDeniedError: true })
    }

    toggleModal = (visible) => {
        this.setState({ modalVisibility: visible });
    }

    render() {
        const { modalVisibility, total_athlete, total_coach, total_team, total_clubs, next_invoice_date, selectedMembersCount, broadcastModal, pending_invites, renewals, loading } = this.state;
        return (
            Helper.isISRLogin() && !this.state.selectedClubId ?
                 <IsrClubs /> :
            <>
                <div className="rgt-content">
                    <div className="dsh-topouter row">
                        <Col sm={12} className="text-center">
                            <h1 className="text-uppercase dsh-topouter__heading">Quick Actions</h1>
                        </Col>
                        {
                            this.state.isSuperAdmin ?
                                <Row className="w-100 justify-content-center ml-0">
                                    <div className="dsh-topouter-col ml-0 mr-0">
                                        <Button onClick={() => this.props.history.push('/create-organization')} className="dsh-topouter__block text-center">
                                            <Image src={Images.MemberGroupNew} alt="Chat Group" width="68" />
                                            <p>Create an Organization</p>
                                        </Button>
                                    </div>
                                </Row>
                                :
                                <Row className="w-100 justify-content-center ml-0">
                                    {
                                        Helper.isAdminOrSubAdmin() &&
                                        <div className="dsh-topouter-col">
                                            <Button className="dsh-topouter__block text-center" onClick={() => this.props.history.push('/teams')}>
                                                <Image src={Images.MemberGroupNew} alt="Member Group" width="68" />
                                                <p>Member Groups</p>
                                            </Button>
                                        </div>
                                    }
                                    <div className="dsh-topouter-col">
                                        <Button onClick={() => this.props.history.push('/groups')} className="dsh-topouter__block text-center">
                                            <Image src={Images.SafeChatIc} alt="Chat Group" width="68" />
                                            <p>SafeChats</p>
                                        </Button>
                                    </div>
                                    <div className="dsh-topouter-col">
                                        <Button className="dsh-topouter__block text-center" onClick={() => this.toggleModal(!modalVisibility)}>
                                            <Image src={Images.AddContactIc} alt="Contact" width="68" />
                                            <p>Invite New Contact</p>
                                        </Button>
                                    </div>
                                    <div className="dsh-topouter-col">
                                        <Button className="dsh-topouter__block text-center" onClick={() => this.props.history.push('/contacts')}>
                                            <Image src={Images.ContactsIc} alt="Contact" width="68" />
                                            <p>Contacts</p>
                                        </Button>
                                    </div>
                                    {
                                        Helper.isCoachOrAdminLogin () &&
                                        <div className="dsh-topouter-col">
                                            <Button className="dsh-topouter__block text-center" onClick={() => Helper.fetchSelectedMembersCount(true, this, null)}>
                                                <Image src={Images.AnnouncementIc} alt="Contact" width="68" />
                                                <p>Announcement To All</p>
                                            </Button>
                                        </div>
                                    }
                               </Row>
                        }
                    </div>
                    <div className="dsh-content">
                        <h3>Home</h3>
                        <Row>
                            {
                                total_clubs !== null &&
                                <Col md={3} sm={6}>
                                    <Link to="/organization">
                                        <div className="dsh-content__col">
                                            <Image src={Images.DashboardTotalMember} alt="Total Members" />
                                            <p>{total_clubs}</p>
                                            <div className="dsh-content__col__desc">
                                                Organizations
                                            </div>
                                        </div>
                                    </Link>
                                </Col>
                            }

                            {
                                Helper.isAdminOrSubAdmin () &&
                                    <Col md={3} sm={6}>
                                        <Link to="">
                                            <div className="dsh-content__col">
                                                <Image src={Images.Renewals} alt="Renewals" width="58" />
                                                <p>{renewals}</p>
                                                <div className="dsh-content__col__desc">
                                                    Renewals
                                                </div>
                                            </div>
                                        </Link>
                                    </Col>
                           }
                            <Col md={3} sm={6}>
                                <Link to="/teams" className={this.state.isSuperAdmin ? 'disabled-link' : ''}>
                                    <div className="dsh-content__col">
                                        <Image src={Images.DashboardTotalMember} alt="Member Groups" width="58" />
                                        <p>{total_team}</p>
                                        <div className="dsh-content__col__desc">
                                            Member Groups
                                        </div>
                                    </div>
                                </Link>
                            </Col>
                            <Col md={3} sm={6}>
                                <Link to="/contacts#instructor" className={this.state.isSuperAdmin ? 'disabled-link' : ''}>
                                    <div className="dsh-content__col">
                                        <Image src={Images.DashboardTotalInstructor} alt="Instructors" width="58" />
                                        <p>{total_coach}</p>
                                        <div className="dsh-content__col__desc">
                                            Instructors
                                        </div>
                                    </div>
                                </Link>
                            </Col>
                            <Col md={3} sm={6}>
                                <Link to="/contacts#student" className={this.state.isSuperAdmin ? 'disabled-link' : ''}>
                                    <div className="dsh-content__col">
                                        <Image src={Images.DashboardTotalStudent} alt="Students" width="58" />
                                        <p>{total_athlete}</p>
                                        <div className="dsh-content__col__desc">
                                            Students
                                        </div>
                                    </div>
                                </Link>
                            </Col>
                            {
                                Helper.isCoachOrAdminLogin() &&
                                <Col md={3} sm={6}>
                                    <Link to="/contacts#pending-invites">
                                        <div className="dsh-content__col">
                                            <Image src={Images.PendingInvites} alt="Pending Invites" width="58"/>
                                            <p>{pending_invites}</p>
                                            <div className="dsh-content__col__desc">
                                                Pending Invites
                                            </div>
                                        </div>
                                    </Link>
                                </Col>
                            }
                        </Row>
                    </div>
                </div>
                <SingleInvite
                    modalVisibility={modalVisibility}
                    toggleModal={this.toggleModal}
                    history={this.props.history}
                    defaultTab={'legalguardian'}
                />
                <ErrorAccessDeniedModal
                    show={this.state.showAccessDeniedError}
                    message={this.state.accessMessage}
                    currentProps={this.props}
                />
                <BroadcastMesaage
                    selectedMembers='all'
                    selectedMembersCount={selectedMembersCount}
                    broadcastModal={broadcastModal}
                    toggleBroadcastModal={Helper.toggleBroadcastModal}
                    deselectAll={this.deselectAll}
                    type = "contact"
                    mainObj = {this}
                />
                <Loader loading={loading} />
            </>
        )
    }
}
export { Dashboard };