/**
 * Start - React
 * Name and Version: react, 16.9.0
 * Download link: https://www.npmjs.com/package/react
 */
import React, {Component} from 'react';
/* End - React */
/**
 * Start - React Router Dom
 * Name and Version: react-router-dom, 5.0.1
 * Download link: https://www.npmjs.com/package/react-router-dom
 */
/* End - React Router Dom */
/**
 * Start - React Bootstrap
 * Name and Version: react-bootstrap, 1.0.0-beta.12
 * Download link: https://www.npmjs.com/package/react-bootstrap
 */
import {Col, Form, Image, OverlayTrigger, Row, Tab, Tabs, Tooltip} from 'react-bootstrap';
/* End - React Bootstrap */
import {DeleteConfirmModal, ErrorModal, SuccessModal, UpdateMiddleNameModal} from '../../components/modal/modalbox';
import {NoRecord} from '../../components/no-record/no-record';
import * as Images from '../../components/common/images';
import * as Enum from '../../components/common/enum';
import './group.scss';
import * as Helper from '../../components/common/functions';
import * as Storage from '../../components/common/storage';
import {Messages} from '../../components/common/message';
import BroadcastMesaage from '../../components/common/broadcast-message';
import Loader from '../../components/loader/loader';
import {GroupList} from '../../components/group/group-list';

class GroupListing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showDiv: false,
            groups: null,
            filteredGroups: [],
            loading: false,
            recordLoaded: false,
            checkedAll: false,
            broadcastBtnLoader: false,
            checkedItems: new Map(),
            selectedGroups: new Map(),
            selectedContacts: [],
            errorMessage: null,
            showError: false,
            broadcastModal: false,
            selectedMembersCount: null,
            sortCriteria: {
                column: 'name',
                direction: 'asc',
                sortingInProcess: false
            },
            showMiddleNameWarning: null,
            deleteConfirmShow: false,
            recordIdForDeletion: null,
            modalLoader: false,
            showSuccess: false,
            msgSuccess: '',
            showMiddleNameWarning: null,
            selectedTab: 0

        };
        this.addMembersToChatGroups = this.addMembersToChatGroups.bind(this);
    }

    componentDidMount() {
        this.getGroupList(this.state.selectedTab);
        const addMiddleName = Storage.Get('add_middle_name');
        if(Helper.isLegalGuardian() && typeof addMiddleName != 'undefined' && addMiddleName == true){
            this.setState({ showMiddleNameWarning: true });
        }
        if(this.props.match.params.is_admin == 1){
            this.setState({selectedContacts: Storage.Get('contacts')}, this.deleteStorageContacts);
        }
    }

    deleteStorageContacts(){
        Storage.Delete('contacts');;
    }

    searchGroupList = (target) => {
        this.setState({ filteredGroups: this.state.groups.filter(x => (x.name).toLowerCase().indexOf(target.value.toLowerCase().trim()) !== -1) })
    }

    handleAllChecked = () => {
        let {  filteredGroups, checkedItems } = this.state;
        !(checkedItems.size === filteredGroups.length) ?
            this.setState({ checkedItems: new Map(), selectedGroups: new Map() }, () => {
                filteredGroups.forEach(group => this.selectGroup(group));
            }) : filteredGroups.forEach(group => this.selectGroup(group));
        this.setState({ filteredGroups: filteredGroups }, () => {
            checkedItems.size === filteredGroups.length ? this.setState({ checkedAll: true }) : this.setState({ checkedAll: false })
        });
    }

    selectGroup = (group) => {
        let {  selectedGroups, checkedItems } = this.state;
        const groupId = group.id,
              groupName = group.name;
        if (typeof selectedGroups.get(groupId) == "undefined") {
            selectedGroups.set(groupId, { groupName, groupId });
            checkedItems.set(groupId, true);
        } else {
            selectedGroups.delete(groupId);
            checkedItems.delete(groupId);
        }
        this.setState({ selectedGroups: selectedGroups, checkedItems: checkedItems }, () => {
            this.state.checkedItems.size === this.state.filteredGroups.length ? this.setState({ checkedAll: true }) : this.setState({ checkedAll: false })
        });
    }

    deselectAll = () => {
        let {  selectedGroups, checkedItems } = this.state;
        selectedGroups.clear();
        checkedItems.clear();
        this.setState({ selectedGroups, checkedItems }, () => {
            this.state.checkedItems.size === this.state.selectedGroups.length ? this.setState({ checkedAll: true }) : this.setState({ checkedAll: false })
        });
    }
    
    getGroupList = (selectedTab) => {
        if (Storage.Get('user') != null) {
            this.setState({ recordLoaded: false,filteredGroups:[] });
            let  selectedClubId = Storage.Get('user').selectedClubId,
                 sortCriteria = this.state.sortCriteria,
                 isAdmin = this.props.match.params.is_admin == 1 ? true : false,
                 apiUrl,
                 me = this;
            if (selectedClubId !== null) {
                if(selectedTab == Enum.GroupList.INDIVIDUAL_CHAT){
                     apiUrl = `group/list-individual/${sortCriteria.column}/${sortCriteria.direction}`;
                }else if(selectedTab == Enum.GroupList.MEMBER_CHAT) {
                    apiUrl = `group/list-team/${selectedClubId}/${sortCriteria.column}/${sortCriteria.direction}`
                }else{
                    apiUrl =`group/list/${selectedClubId}/web/${sortCriteria.column}/${sortCriteria.direction}/${isAdmin}`
                }
                Helper.axiosInstance().get(apiUrl)
                    .then((response) => {
                        this.setState({
                            groups: response.data,
                            filteredGroups: response.data,
                            recordLoaded: true,
                            isCoachOrAdminLogin: Helper.isCoachOrAdminLogin()

                        });
                    }, error => {
                        this.setState({ recordLoaded: true });
                        if (error.response.status === 401) {
                            this.setState({ recordLoaded: true });
                        }
                    })
                    .catch(function (error) {
                        me.setState({ recordLoaded: true });
                    })
                    .finally(() => {
                        let { sortCriteria } = this.state;
                        sortCriteria.sortingInProcess = false;
                        me.setState({sortCriteria: sortCriteria, recordLoaded: true });
                    });
            }
        }
    }

    sortHandler = (column) => {
        const {sortCriteria, selectedTab} = this.state;
        if(!sortCriteria.sortingInProcess){
            let newSortCriteria = sortCriteria;
                newSortCriteria.column = column;
            if(sortCriteria.column  == column){
                newSortCriteria.direction = (sortCriteria.direction== 'asc') ? 'desc' : 'asc';
            } else {
                newSortCriteria.direction = 'asc';
            }
            newSortCriteria.sortingInProcess = true;

            this.setState({sortCriteria: newSortCriteria}, this.getGroupList(selectedTab));
        }
    }

    onDeleteGroupClick = (groupId) => {
        this.setState({deleteConfirmShow:true, recordIdForDeletion:groupId});
    }
    onDeleteGroupConfirmClick = () => {
        if (this.state.recordIdForDeletion != null) {
            let me = this;
            this.setState({
                modalLoader: true,
            })
            let url = this.state.selectedTab == Enum.GroupList.SAFE_CHAT ? 'group/delete' : 'group/delete-individual';
            Helper.axiosInstance()
                .post(url, {
                    group_id: this.state.recordIdForDeletion
                })
                .then((response) => {
                    if (response.data.success === true) {
                        me.setState({
                            deleteConfirmShow:false,
                            recordIdForDeletion:null,
                            modalLoader: false,
                            showSuccess: true,
                            msgSuccess: response.data.message
                        }, me.getGroupList(me.state.selectedTab));
                    } else {
                        me.setState({
                            modalLoader: false
                        });
                        me.showError(response.data.message);
                    }
                })
                .catch(function (error) {
                    console.log('error', error)
                    me.setState({ errorMessage: Messages.SERVER_ERROR, showError: true, modalLoader: false});
                });
        }
    }
    onDeleteGroupCancelClick = () => {
        this.setState({ deleteConfirmShow: false});
    }
    showError = (msg) => {
        this.setState({ errorMessage: msg, showError: true, loading: false });
    }

    showSuccess = (msg) => {
        this.setState({ selectedGroups: new Map(), checkedItems: new Map(), showSuccess: true, msgSuccess: msg, loading: false });
    }

    handleClose = () => {
        this.setState({showSuccess: false, msgSuccess: "" });
    }

    addMembersToChatGroups(){
        const {selectedGroups, selectedContacts} = this.state;
        if (selectedContacts != null && selectedContacts.length > 0) {
            if(selectedGroups.size > 0){
                let me = this,
                    data = {},
                    memberIds = [];
                for (let val of selectedGroups.values()) {
                    memberIds.push(val.groupId);
                }
                data.group_id = memberIds;
                data.member_id = this.state.selectedContacts;
                this.setState({loading: true});
                Helper.axiosInstance().post(`group/add-member-multi`, data)
                    .then((response) => {
                        if(response.data.success){
                            this.setState({selectedContacts: []});
                            me.showSuccess(response.data.message);
                        } else {
                            me.showError(response.data.error);
                        }
                    }, error => {
                        me.showError(Messages.SERVER_ERROR)
                    })
                    .catch(function (error) {
                        me.showError(Messages.SERVER_ERROR)
                    });
            } else {
                this.setState({  errorMessage: Enum.groupSelectionWarning, showError: true});
            }
        }
    }

    announcementBtnHandler = () => {
        const {selectedGroups, selectedTab} = this.state;
        if(selectedGroups.size > 0){
            let groupType = selectedTab == Enum.GroupList.MEMBER_CHAT
                ? 'teams'
                : selectedTab == Enum.GroupList.INDIVIDUAL_CHAT
                    ? 'individual_groups'
                    : 'groups';
            Helper.fetchSelectedMembersCount(false, this, groupType);
        } else {
            this.setState({  errorMessage: Enum.groupSelectionWarning, showError: true});
        }
    }

    reloadContacts = (selectedTab) => {
        let selectedGroups = this.state.selectedGroups,
            checkedItems = this.state.checkedItems;
        selectedGroups.clear();
        checkedItems.clear();
         this.setState(
            {
                checkedAll: false,
                filteredGroups: [],
                selectedTab: selectedTab,
                recordLoaded: false
            },
            () => {
                this.getGroupList(this.state.selectedTab);
            }
        );
    };


    render() {
        const {checkedAll, checkedItems, selectedGroups, selectedMembersCount, broadcastModal, sortCriteria, msgSuccess, showSuccess, selectedContacts, loading, recordLoaded, selectedTab} = this.state;
        let sortImage = Images.ArrowSort;
        if(typeof sortCriteria != 'undefined' && sortCriteria.direction == 'desc'){
            sortImage = Images.ArrowDown;
        } else if(typeof sortCriteria != 'undefined' && sortCriteria.direction == 'asc') {
            sortImage = Images.ArrowUp;
        }
        let isAdmin = this.props.match.params.is_admin == 1 ? true : false;
        return (
            <>
                <div className="rgt-content">
                    <Loader loading={loading} />
                    <Row className="row--space align-items-center">
                        <Col md={4}>
                            <h1>SafeChats</h1>
                            {
                                selectedGroups.size > 0 &&
                                <label className="selected-count">{selectedGroups.size == 1 ? `${selectedGroups.size} group selected` : `${selectedGroups.size} groups selected`} </label>
                            }
                        </Col>
                        <Col md={8}>
                            <div className="c-filter-outer">
                                <div className="wgt-btnwrap">
                                    {
                                        Helper.isCoachOrAdminLogin() &&
                                        <>
                                            {
                                                selectedTab != Enum.GroupList.INDIVIDUAL_CHAT
                                                    ? <OverlayTrigger placement="top"
                                                                    overlay={
                                                                        <Tooltip id={`tooltip`}>
                                                                            Send Announcement
                                                                        </Tooltip>}>
                                                        <a href={null} onClick={() => {
                                                            this.announcementBtnHandler()
                                                        }}>
                                                            <div className="outline-circle">
                                                                <Image src={Images.AnnouncementRed} alt="Upload" width="30"/>
                                                            </div>
                                                        </a>
                                                    </OverlayTrigger>
                                                    : null
                                            }
                                            {

                                                (selectedContacts != null && selectedContacts.length && isAdmin) ?
                                                    <OverlayTrigger placement="top"
                                                                    overlay={
                                                                        <Tooltip id={`tooltip`}>
                                                                            Add Contact(s)
                                                                        </Tooltip>}>
                                                        <a href={null} onClick={() => {
                                                            this.addMembersToChatGroups()
                                                        }}>
                                                            <div className="outline-circle">
                                                                <Image src={Images.AddContactRed} alt="Add member"
                                                                       width="30"/>
                                                            </div>
                                                        </a>
                                                    </OverlayTrigger>
                                                    : null

                                            }
                                            </>
                                    }

                                    {
                                        selectedGroups.size > 0 &&
                                        <OverlayTrigger placement="auto"
                                                        overlay={
                                                            <Tooltip id={`tooltip`}>
                                                                Cancel
                                                            </Tooltip>}>
                                            <a href={null} onClick= {() => {
                                                this.deselectAll();
                                            }}>
                                                <div className="outline-circle">
                                                    <Image src={Images.Cancel} alt="Cancel" width="25" />
                                                </div>
                                            </a>
                                        </OverlayTrigger>
                                    }
                                </div>
                            </div>
                        </Col>
                        <Col md={12}>
                            <div className="widget">
                                <div>
                                    <div className="tab-search tab-search--big">
                                        <Form inline onSubmit={e => { e.preventDefault(); }}>
                                            <input type="text" name="focus" maxLength="100" placeholder="Search" onChange={(e) => this.searchGroupList(e.target)} className="form-control tab-search__box" />
                                            <button className="close-icon" onClick={(e) => this.searchGroupList(e.target)} type="reset"></button>
                                        </Form>
                                    </div>
                                    <Tabs defaultActiveKey={Enum.GroupList.SAFE_CHAT}  onSelect={this.reloadContacts}>
                                        <Tab eventKey="0" title="Group SafeChat">
                                            <GroupList
                                                recordLoaded={recordLoaded}
                                                selectedTab={selectedTab}
                                                data={this.state.filteredGroups}
                                                name={"SafeChat"}
                                                sortHandler={this.sortHandler}
                                                sortCriteria={sortCriteria}
                                                sortImage={sortImage}
                                                handleAllChecked={this.handleAllChecked}
                                                checkedAll={checkedAll}
                                                checkedItems={checkedItems}
                                                selectGroup={this.selectGroup}
                                                detail={"group-detail"}
                                                onDeleteGroupClick={this.onDeleteGroupClick}
                                                allProps={this.props}
                                            />
                                        </Tab>
                                        <Tab eventKey={Enum.GroupList.INDIVIDUAL_CHAT} title="Individual SafeChat">
                                            <GroupList
                                                recordLoaded={recordLoaded}
                                                selectedTab={selectedTab}
                                                data={this.state.filteredGroups}
                                                name={"Individual Group"}
                                                sortHandler={this.sortHandler}
                                                sortCriteria={sortCriteria}
                                                sortImage={sortImage}
                                                handleAllChecked={this.handleAllChecked}
                                                checkedAll={checkedAll}
                                                checkedItems={checkedItems}
                                                selectGroup={this.selectGroup}
                                                onDeleteGroupClick={this.onDeleteGroupClick}
                                                allProps={this.props}
                                            />
                                        </Tab>
                                        <Tab eventKey={Enum.GroupList.MEMBER_CHAT} title="Member SafeChat">
                                            <GroupList
                                                recordLoaded={recordLoaded}
                                                selectedTab={selectedTab}
                                                data={this.state.filteredGroups}
                                                name={"Member Group"}
                                                sortHandler={this.sortHandler}
                                                sortCriteria={sortCriteria}
                                                sortImage={sortImage}
                                                handleAllChecked={this.handleAllChecked}
                                                checkedAll={checkedAll}
                                                checkedItems={checkedItems}
                                                selectGroup={this.selectGroup}
                                                detail={"team-details"}
                                                allProps={this.props}
                                            />
                                        </Tab>
                                    </Tabs>
                                    {(this.state.recordLoaded && this.state.filteredGroups.length === 0 ) && <NoRecord />}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
                <BroadcastMesaage
                    selectedMembers = {selectedGroups}
                    selectedMembersCount={selectedMembersCount}
                    broadcastModal={broadcastModal}
                    toggleBroadcastModal={Helper.toggleBroadcastModal}
                    checkedAll={checkedAll}
                    deselectAll={this.deselectAll}
                    mainObj={this}
                    type = {selectedTab == Enum.GroupList.MEMBER_CHAT ? 'team_chat' : selectedTab == Enum.GroupList.INDIVIDUAL_CHAT ? 'individual_group' : 'group'}
                />
                <SuccessModal
                    successConfirmShow={showSuccess}
                    onConfirmClick={this.handleClose}
                    successText={msgSuccess}
                    successButtonText={"OK"}
                    onHide={this.handleClose}
                />
                <ErrorModal
                    show={this.state.showError}
                    message={this.state.errorMessage}
                    onConfirmClick={() => { this.setState({ showError: false }) }}
                />
                <DeleteConfirmModal
                    deleteConfirmShow={this.state.deleteConfirmShow}
                    onHide={this.onDeleteGroupCancelClick}
                    onCancelClick={this.onDeleteGroupCancelClick}
                    onConfirmClick={this.onDeleteGroupConfirmClick}
                    confirmText="Do you really want to delete this SafeChat group?"
                    confirmButtonText="Delete"
                    confirmType="Delete"
                    modalLoader={this.state.modalLoader}
                />
                <UpdateMiddleNameModal
                    show={this.state.showMiddleNameWarning}
                    handleLogout={this.props.handleLogout}
                    currentProps={this.props}
                />
            </>
        );
    }
}
export { GroupListing };