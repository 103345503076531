/**
 * Start - React
 * Name and Version: react, 16.9.0
 * Download link: https://www.npmjs.com/package/react
 */
import React, { Component } from 'react';
/* End - React */

/**
 * Start - axios
 * Name and Version: axios, 0.19.0
 * Download link: https://www.npmjs.com/package/axios
 */
import axios from 'axios';
/* End - axios */

/**
 * Start - Lodash.debounce
 * Name and Version: lodash.debounce, 4.0.8
 * Download link: https://www.npmjs.com/package/lodash.debounce
 */
import debounce from 'lodash.debounce';
/* End - Lodash.debounce */

/**
 * Start - React Bootstrap
 * Name and Version: react-bootstrap, 1.0.0-beta.12
 * Download link: https://www.npmjs.com/package/react-bootstrap
 */
import { Form, Row, Col, Dropdown, Tab, Tabs, Image, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
/* End - React Bootstrap */

/**
 * Start - Formik
 * Name and Version: formik, 1.5.8
 * Download link: https://www.npmjs.com/package/formik
 */
import { Formik, Field, Form as FormIkForm, ErrorMessage } from 'formik';
/* End - Formik */

/**
 * Start - Yup
 * Name and Version: yup, 0.27.0
 * Download link: https://www.npmjs.com/package/yup
 */
import * as Yup from 'yup';
/* End - Yup */

import { Messages } from '../../components/common/message';
import { ErrorModal, DeleteConfirmModal, ErrorAccessDeniedModal, SuccessModal } from '../../components/modal/modalbox';
import * as Images from '../../components/common/images';
import * as Helper from '../../components/common/functions';
import * as Storage from '../../components/common/storage';
import CreateChatGroup from '../../components/chat/create-chat-group';
import AddMemberToTeam from './add-member-to-team';
import * as Enum from '../../components/common/enum';
import { SmallLoader } from '../../components/small-loader/sm-loader';
import { NoRecord } from '../../components/no-record/no-record';
import Loader from '../../components/loader/loader';
import { TeamMessages } from './team-message';
import { ContactList } from '../../components/contact/contact-list';
import { PendingContactList } from '../../components/contact/pending-contact-list';
import EditPendingLG from '../contacts/edit-pending-lg';
import EditPendingInstructor from '../contacts/edit-pending-instructor';
import ResendInvite from '../contacts/resend-invite';
import ResendAllInvite from '../contacts/resend-all-invite';
import FixedActionBar from '../../components/common/fixed-action-bar';
import './team.scss';
import { Link } from 'react-router-dom';
class TeamDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTeam: { name: '', description: '', location: '' },
            checked: false,
            innerCheckboxChecked: false,
            show: false,
            showError: false,
            showTeamEditLoader: false,
            errorMessage: null,
            updateTeamDivShow: false,
            showAccessDeniedError: false,
            teamshow: false,
            disabled: false,
            teamId: props.match.params.team_id,
            file: {
                imagesrc: null,
                data: null
            },
            successConfirmShow: false,
            successText: '',
            filterCriteria: {
                searchKeyword: '',
                teams: [props.match.params.team_id],
                status: 1,
                role: null,
                page: 1,
                pageSize: 40
            },
            pendingFilterCriteria: {
                searchKeyword: '',
                teams: [props.match.params.team_id],
                page: 1,
                pageSize: 150,
                status: 1,
                role: null,
            },
            updateContact: {
                deleteConfirmShow: false,
                memberIdForDeletion: null,
                memberRoleForDeletion: null,
                confirmButtonText: "Remove",
                confirmText: TeamMessages.CONFIRM_REMOVE_GROUP,
                confirmTypeStatus: "Deactivate",
                newStatus: 0
            },

            groupModal: false,
            checkedItems: new Map(),
            selectedMember: new Map(),
            activeTeamList: null,
            recordLoaded: false,
            totalRecords: null,
            userInfo: Storage.Get('user'),
            cancelToken: axios.CancelToken,
            cancel: undefined,
            checkedAll: false,
            showFields: false,
            filteredContacts: [],
            pendingContacts: [],
            checkedPendingItems: new Map(),
            selectedPendingMember: new Map(),
            editPendingModalVisibility: false,
            editInstructorModalVisibility: false,
            resendInviteVisibility: false,
            resendAllInviteVisibility: false,
            pendingInviteFilter: false,
            pendingCancel: undefined,
            pendingCancelToken: axios.CancelToken,
            editPendingUser: {
                invite_id: '',
                role_id: '',
                guardian_first_name: '',
                guardian_last_name: '',
                guardian_email: '',
                user_first_name: '',
                user_last_name: '',
                user_email: '',
                user_photo: '',
                guardian_photo: ''
            },
            serverValid: {
                name: '',
                description: '',
                location: ''
            },
            sortCriteria: {
                column: 'first_name',
                direction: 'asc',
                sortingInProcess: false
            },
        };
        this.handlePhotoChange = this.handlePhotoChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.inputFocus = this.inputFocus.bind(this);
        this.inputBlur = this.inputBlur.bind(this);
        this.showBulkUpload = this.showBulkUpload.bind(this);
        this.reloadPendingContacts = this.reloadPendingContacts.bind(this);

        /* Using Debounce to prevent repeated events
             Windows scroll event debounce */
        this.doDebouncedTableScroll = debounce(this.onTableScroll, 100);
        /*  Filter Criteria Debounce */
        let filterDelay = 500;
        this.doDebouncedSearchKeywordChange = debounce(this.onSearchKeywordChange, filterDelay);

    }

    onDeactivateContactClick = (item) => {
        this.setState(prevState => {
            let updateContact = Object.assign({}, prevState.updateContact);
            updateContact.deleteConfirmShow = true;
            updateContact.memberIdForDeletion = item.id;
            updateContact.memberRoleForDeletion = item.role_id;
            return { updateContact };
        });
    };

    componentDidMount = () => {
        window.addEventListener('scroll', this.doDebouncedTableScroll);
        window.scrollTo(0, 0);
        this.setState({ userId: this.state.userInfo.id }
            , () => {
                this.getTeamList();
                this.getTeamInfo();
                this.getContactList();
            }
        );
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.doDebouncedTableScroll)
    }

    componentWillReceiveProps = (nextProps) => {
        this.setState({ teamId: nextProps.match.params.team_id }, () => {
            this.getTeamInfo();
            this.getContactList();
        });
    }

    onSearchKeywordChange = (target) => {
        let newPendingFilterCriteria = this.state.pendingFilterCriteria;
        newPendingFilterCriteria.searchKeyword = target.value.toLowerCase();
        let newFilterCriteria = this.state.filterCriteria;
        newFilterCriteria.searchKeyword = target.value.toLowerCase();
        if (Helper.isCoachOrAdminLogin() && this.state.pendingInviteFilter) {
            this.reloadPendingContacts(newPendingFilterCriteria);
        }
        else {
            this.reloadContacts(newFilterCriteria);
        }
    }

    toggleMemberModal = (visible) => {
        this.setState({ show: visible });
    }

    removeMembersFromTeam = () => {
        let instructor = true;
        // eslint-disable-next-line
        for (let val of this.state.selectedMember.values()) {
            if (val.roleId.includes(4) && !Helper.isAdminOrSubAdmin()) {
                this.setState({ errorMessage: "You cannot delete an instructor. Kindly uncheck it and Try Again.", showError: true });
                instructor = false;
            }
        }
        if (instructor) {
            this.toggleMemberModal(!this.state.show)
        }
    }

    selectMember(member) {
        const userId = member.id;

        let selectedMember = this.state.selectedMember;
        let checkedItems = this.state.checkedItems;

        if (typeof selectedMember.get(userId) == "undefined") {
            const name = Helper.getMemberName(member),
                avatar = Helper.generateAvatar(member),
                username = member.username,
                guardians = member.guardian,
                roleId = member.role_id;
            selectedMember.set(userId, { name, avatar, username, guardians, userId, roleId });
            checkedItems.set(userId, true);
        } else {
            selectedMember.delete(userId);
            checkedItems.delete(userId);
        }
        const guardianCount = Helper.guardianCount(selectedMember)+1;

        this.setState({ selectedMember, checkedItems, guardianCount }, () => {
            this.state.checkedItems.size === this.state.filteredContacts.length ? this.setState({ checkedAll: true }) : this.setState({ checkedAll: false })

            if(parseInt(selectedMember.size)+parseInt(guardianCount) <= process.env.REACT_APP_GROUP_LIMIT) {
                this.setState({ disabled: false, showError: false})
             }

        });
    }

    handleAllChecked = () => {
        let contacts = this.state.filteredContacts
        !(this.state.checkedItems.size === this.state.filteredContacts.length) ?
            this.setState({ checkedItems: new Map(), selectedMember: new Map() }, () => {
                contacts.forEach(contact => this.selectMember(contact));
            }) : contacts.forEach(contact => this.selectMember(contact));
        this.setState({ filteredContacts: contacts }, () => {
            this.state.checkedItems.size === this.state.filteredContacts.length ? this.setState({ checkedAll: true }) : this.setState({ checkedAll: false })
        })
    }

    handlePendingAllChecked = () => {
        let { pendingContacts, checkedPendingItems } = this.state;
        !(checkedPendingItems.size === pendingContacts.length) ?
            this.setState({ checkedPendingItems: new Map(), selectedPendingMember: new Map() }, () => {
                pendingContacts.forEach(contact => this.selectPendingMembers(contact));
            }) : pendingContacts.forEach(contact => this.selectPendingMembers(contact));
        this.setState({ pendingContacts: this.state.pendingContacts }, () => {
            checkedPendingItems.size === this.state.pendingContacts.length ? this.setState({ checkedAll: true }) : this.setState({ checkedAll: false })
        })
    }

    onRoleTabSelect = (key) => {
        if (key === 'pendingInvites') {
            this.setState({ pendingInviteFilter: true }, () => {
                if (this.state.pendingInviteFilter && this.state.filterCriteria.searchKeyword !== null) {
                    let newPendingFilterCriteria = this.state.pendingFilterCriteria;
                    newPendingFilterCriteria.searchKeyword = this.state.filterCriteria.searchKeyword.toLowerCase();
                    this.reloadPendingContacts(newPendingFilterCriteria);
                }
            });

        }
        else {
            let newFilterCriteria = this.state.filterCriteria;
            newFilterCriteria.role = key === "0" ? null : key;
            this.reloadContacts(newFilterCriteria);
            this.setState({ pendingInviteFilter: false });
        }
    }

    reloadContacts = (newFilterCriteria) => {
        newFilterCriteria.page = 1;
        this.setState({ filterCriteria: newFilterCriteria, filteredContacts: [], recordLoaded: false },
            () => {
                this.getContactList();
            });
    }

    reloadPendingContacts = (newPendingFilterCriteria) => {
        newPendingFilterCriteria.page = 1;
        this.setState({
            pendingFilterCriteria: newPendingFilterCriteria, checkedAll: false,
            checkedItems: new Map(), pendingContacts: [], recordLoaded: false, selectedMember: new Map()
        },
            () => { this.getPendingContacts(); });
    }

    onTableScroll = () => {
        // Don't proceed if:
        // old records are already loading
        // there's nothing left to load
        if (this.state.pendingInviteFilter) {
            if (!this.state.recordLoaded || this.state.pendingRecords === this.state.pendingContacts.length || this.state.totalRecords < this.state.pageSize) return;
        }
        else {
            if (!this.state.recordLoaded || this.state.totalRecords === this.state.filteredContacts.length || this.state.totalRecords < this.state.pageSize) return;
        }
        // Reduce 20percent from the offsetHeight
        let reduceOffset = document.documentElement.offsetHeight - (20 / 100) * document.documentElement.offsetHeight;
        // Check if scroll has hit bottom
        if (window.innerHeight + document.documentElement.scrollTop >= reduceOffset) {
            if (this.state.pendingInviteFilter) {
                let newPendingFilterCriteria = this.state.pendingFilterCriteria;
                newPendingFilterCriteria.page = newPendingFilterCriteria.page + 1;
                this.setState({ pendingFilterCriteria: newPendingFilterCriteria });
                this.getPendingContacts();
            }
            else {
                let newFilterCriteria = this.state.filterCriteria;
                newFilterCriteria.page = newFilterCriteria.page + 1;
                this.setState({ filterCriteria: newFilterCriteria });
                this.getContactList();
            }
        }
    }

    getContactList = () => {
        this.setState({ recordLoaded: false });
        let sortCriteria = this.state.sortCriteria;
        if (this.state.userInfo != null) {
            let selectedClubId = this.state.userInfo.selectedClubId;
            let filterCriteria = this.state.filterCriteria;
            if (selectedClubId != null) {
                let cancel = this.state.cancel;
                let cancelToken = this.state.cancelToken;
                if (this.state.cancel !== undefined) {
                    cancel();
                }
                let me = this;
                Helper.axiosInstance()
                    .post(`contacts`
                        , JSON.stringify({
                            club_id: selectedClubId,
                            keyword: filterCriteria.searchKeyword,
                            team_id: [this.state.teamId],
                            status: filterCriteria.status,
                            role_id: filterCriteria.role,
                            page_size: filterCriteria.pageSize,
                            page: filterCriteria.page,
                            sort_by: sortCriteria.column,
                            sort_dir: sortCriteria.direction
                        }), {
                        cancelToken: new cancelToken(function executor(c) {
                            me.setState({ cancel: c });
                        })
                    })
                    .then((response) => {
                        if (response.data.success === true) {
                            if (this.state.filterCriteria.page === 1) {
                                this.setState({
                                    filteredContacts: response.data.members
                                });
                            }
                            else {
                                let existingRecords = this.state.filteredContacts;
                                this.setState({ filteredContacts: existingRecords.concat(response.data.members) });
                            }
                            this.setState({ recordLoaded: true, totalRecords: response.data.count, });
                        }
                    })
                    .catch((error) => {
                        this.setState({ errorMessage: Messages.SERVER_ERROR, showError: true, });
                    })
                    .finally(() => {
                    let { sortCriteria } = this.state;
                    sortCriteria.sortingInProcess = false;
                    this.setState({sortCriteria: sortCriteria, recordLoaded: true });
                });
            }
        }
    }

    getPendingContacts = () => {
        this.setState({ recordLoaded: false });
        if (this.state.userInfo != null) {
            const selectedClubId = this.state.userInfo.selectedClubId,
                filterCriteria = this.state.pendingFilterCriteria;
            if (selectedClubId != null) {
                let cancelReq = this.state.pendingCancel,
                    pendingCancelReq = this.state.pendingCancelToken;
                if (this.state.pendingCancel !== undefined) {
                    cancelReq();
                }
                let me = this;
                Helper.axiosInstance()
                    .post(`invite/pending`
                        , JSON.stringify({
                            club_id: selectedClubId,
                            keyword: filterCriteria.searchKeyword,
                            page_size: filterCriteria.pageSize,
                            page: filterCriteria.page,
                            team_id: [this.state.teamId],
                            status: filterCriteria.status,
                        }), {
                        pendingCancelReq: new pendingCancelReq(function executor(c) {
                            me.setState({ cancelReq: c });
                        })
                    })
                    .then((response) => {
                        if (response.data.success === true) {
                            if (this.state.pendingFilterCriteria.page === 1) {
                                this.setState({
                                    pendingContacts: response.data.data,
                                    totalRecords: response.data.count
                                });
                            }
                            else {
                                let existingRecords = this.state.pendingContacts;
                                this.setState({ pendingContacts: existingRecords.concat(response.data.data) });
                            }
                            this.setState({ pendingRecords: response.data.count, });
                        }
                        else {
                            this.showError(Messages.SERVER_ERROR);
                        }
                    })
                    .catch((error) => {
                        this.showError(Messages.SERVER_ERROR);
                    })
                    .finally(() => {
                        this.setState({ recordLoaded: true });
                    });
            }
        }
    }

    getTeamInfo() {
        Helper.axiosInstance().get(`team/detail/${this.state.teamId}`)
            .then((response) => {
                if (response) {
                    this.setState({ selectedTeam: response.data.team });
                }
            })
            .catch((error) => {
                if ((error.response.status === 403 || error.response.status === 404) && error.response.data !== undefined) {
                    this.setState({ errorMessage: error.response.data.error, showAccessDeniedError: true });
                } else {
                    this.setState({ errorMessage: Messages.SERVER_ERROR, showError: true, });
                }
            });
    }

    getTeamList() {
        Helper.axiosInstance().get(`team/list/${this.state.userInfo.selectedClubId}/web`)
            .then((response) => {
                if (response) {
                    let activeTeams = [];
                    response.data.my_teams.map((row, i) => {
                        if (row.status === 1) {
                            activeTeams.push(row);
                        }
                    })
                    this.setState({
                        activeTeamList: activeTeams,
                        showFields: true,
                    });
                }
            })
            .catch((error) => {
                this.setState({ errorMessage: Messages.SERVER_ERROR, showError: true, });
            });
    }

    handleChange() {
        this.setState({
            checked: !this.state.checked
        })
    }

    setTeamShow(actiona) {
        this.setState({
            teamshow: actiona
        })
    }

    handlePhotoChange = (event) => {

        if (event.target.files[0] !== null && event.target.files[0] !== undefined) {
            if (event.target.files[0].size / 1024 > 6000) {
                this.setState({ errorMessage: TeamMessages.PHOTO_MAX_SIZE, showError: true, });
                event.target.value = null;
                return;
            }
            if (event.target.files[0].type !== "image/jpeg" && event.target.files[0].type !== "image/jpg") {
                this.setState({ errorMessage: TeamMessages.PHOTO_TYPE_ALLOWED, showError: true, });
                event.target.value = null;
                return;
            }
            this.setState({ showPhotoUploader: true });
            const formData = new FormData();
            formData.append('id', this.state.teamId);
            formData.append('photo', event.target.files[0]);
            Helper.axiosInstance().post('team/update-image', formData,
                {
                    'Content-Type': 'multipart/form-data',
                }
            ).then((response) => {
                if (response.data.success) {
                    let selectedTeam = this.state.selectedTeam;
                    selectedTeam.photo = response.data.imageUrl;
                    this.setState({
                        selectedTeam: selectedTeam,
                        successText: TeamMessages.PHOTO_UPLOADED,
                        successConfirmShow: true,
                        showPhotoUploader: false
                    });
                }
                else {
                    this.setState({ errorMessage: TeamMessages.PHOTO_UPLOAD_FAIL, showError: true, showPhotoUploader: false });
                }
            })
                .catch((error) => {
                    this.setState({ errorMessage: TeamMessages.PHOTO_UPLOAD_FAIL, showError: true, showPhotoUploader: false });
                });
        }
    }

    onSuccessConfirmClick = () => {
        this.setState({
            successConfirmShow: false
        })
    }

    onTeamEditClick = () => {
        this.setState({ updateTeamDivShow: !this.state.updateTeamDivShow });
        this.formik.resetForm();
    }

    onTeamEditSubmit = (fields) => {
        let me = this;
        this.setState({ showTeamEditLoader: true });
        Helper.axiosInstance().put('team/update-name', {
            id: this.state.teamId,
            name: fields.name.trim(),
            description: fields.description.trim(),
            club_id: this.state.userInfo.selectedClubId,
            location: fields.location.trim()
        })
            .then((response) => {
                if (response.data.success === true) {
                    me.resetServerError();
                    let selectedTeam = this.state.selectedTeam;
                    selectedTeam.name = fields.name;
                    selectedTeam.description = fields.description;
                    selectedTeam.location = fields.location;
                    this.setState({
                        successConfirmShow: true,
                        successText: response.data.message,
                        selectedTeam: selectedTeam,
                    });
                    this.onTeamEditClick();
                    this.getTeamList();
                    this.getContactList();
                }
                else {
                    const getErrors = response.data.error;
                    if(typeof getErrors === 'object'){
                        let serverValid = me.state.serverValid;
                        // eslint-disable-next-line
                        for (let errorIndex in getErrors) {
                            if (getErrors[errorIndex][0] !== "") {
                                serverValid[errorIndex] = getErrors[errorIndex][0];
                            }
                        }
                        this.setState({ serverValid: serverValid });
                    }else{
                        this.setState({ errorMessage: Messages.SERVER_ERROR, showError: true, });
                    }
                }
                this.setState({ showTeamEditLoader: false });
            })
            .catch((error) => {
                this.setState({ showTeamEditLoader: false });
            });
    }

    resetServerError() {
        this.setState({
            serverValid : {
                name: '',
                description: '',
                location: ''
            }
        });
    }

    deleteSelectedMember = (id) => {
        let selectedMember = this.state.selectedMember,
            checkedItems = this.state.checkedItems;
        selectedMember.delete(id);
        checkedItems.delete(id);
        const guardianCount = Helper.guardianCount(selectedMember);
        this.setState({
            selectedMember,
            checkedItems,
            guardianCount
        });
        if (selectedMember.size === 0) {
            this.toggleGroupModal(false);
        }
    }
    deselectAll = () =>{
        let selectedMember = this.state.selectedMember,
            checkedItems = this.state.checkedItems;
        selectedMember.clear();
        checkedItems.clear();

        this.setState({ selectedMember, checkedItems }, () => {
            this.state.checkedItems.size === this.state.filteredContacts.length ? this.setState({ checkedAll: true }) : this.setState({ checkedAll: false })
        });
    }

    deselectPendingAll = () => {
        let selectedPendingMember = this.state.selectedPendingMember;
        let checkedPendingItems = this.state.checkedPendingItems;
        selectedPendingMember.clear();
        checkedPendingItems.clear();
        this.setState({ selectedPendingMember, checkedPendingItems }, () => {
            this.state.checkedPendingItems.size === this.state.pendingContacts.length ? this.setState({ checkedAll: true }) : this.setState({ checkedAll: false })
        });
    }

    toggleGroupModal = (visible) => {
        let selectedMember = this.state.selectedMember;
        const guardianCount = Helper.guardianCount(selectedMember)+1;
        if(selectedMember.size+guardianCount > process.env.REACT_APP_GROUP_LIMIT) {
            this.setState({  errorMessage: `SafeChat group should be limited to ${process.env.REACT_APP_GROUP_LIMIT} members including students, instructors, and guardians. Please note, all legal guardians of a student are added to the SafeChat group.`, disabled: true, showError: true})
        }else{
            this.setState({ groupModal: visible, disabled: false, showError: false})
        }

    }

    onDeactivateContactConfirmClick = () => {
        if (this.state.updateContact.memberIdForDeletion != null) {
            this.setState({
                modalLoader: true,
            })
            Helper.axiosInstance()
                .delete('team/remove-member-multi',
                    {
                        data: {
                            team_id: this.state.teamId,
                            member_id: [this.state.updateContact.memberIdForDeletion]
                        }
                    })
                .then((response) => {
                    if (response.data.success === true) {
                        this.setState({
                            filteredContacts: this.state.filteredContacts.filter(x => x.id !== this.state.updateContact.memberIdForDeletion),
                            totalRecords: this.state.totalRecords - 1
                        }, () => {
                            this.deleteSelectedMember(this.state.updateContact.memberIdForDeletion);
                        });

                        this.setState(prevState => {
                            let updateContact = Object.assign({}, prevState.updateContact);
                            let selectedTeam = Object.assign({}, prevState.selectedTeam);
                            // Decrease count if kid/athlete was deleted
                            if (this.state.updateContact.memberRoleForDeletion.includes(Enum.UserRoles.ATHLETE)) {
                                selectedTeam.count = selectedTeam.count - 1;
                            }
                            updateContact.deleteConfirmShow = false;
                            updateContact.memberIdForDeletion = null;
                            updateContact.memberRoleForDeletion = null;
                            this.setState({
                                modalLoader: false,
                            })
                            return { updateContact, selectedTeam };
                        });
                    }
                    else {
                        this.setState({ errorMessage: response.data.error, showError: true, modalLoader: false });
                    }
                })
                .catch((error) => {
                    this.setState({ errorMessage: Messages.SERVER_ERROR, showError: true, modalLoader: false, });
                });
        }
    }

    onDeactivateContactCancelClick = () => {
        this.setState(prevState => {
            let updateContact = Object.assign({}, prevState.updateContact);
            updateContact.deleteConfirmShow = false;
            return { updateContact };
        })
    }
    inputFocus(event) {
        event.target.nextElementSibling.classList.add("label-active");
    }
    inputBlur(event) {
        if (event.target.value.length === 0) {
            event.target.nextElementSibling.classList.remove("label-active");
        }
    }

    toggleLoader = (visible) => {
        this.setState({ loading: visible });
    }

    showBulkUpload = () => {
        this.props.history.push('/bulk-upload/'+this.state.teamId);
    }

    teamName = () => {
        const selectedTeam = this.state.selectedTeam;
        let output = selectedTeam.name;
        output += selectedTeam.location ? ` - ${selectedTeam.location}` : "";
        return output;
    }

    selectPendingMembers = (member) => {
        const userId = member.invite_id
        let selectedPendingMember = this.state.selectedPendingMember;
        let checkedPendingItems = this.state.checkedPendingItems;
        if (typeof selectedPendingMember.get(userId) == "undefined") {
            const name = Helper.getMemberName(member),
                avatar = Helper.generateAvatar(member),
                username = member.username,
                guardians = member.guardian;
            selectedPendingMember.set(userId, { name, avatar, username, guardians, userId });
            checkedPendingItems.set(userId, true);
        } else {
            selectedPendingMember.delete(userId);
            checkedPendingItems.delete(userId);
        }
        this.setState({ selectedPendingMember: selectedPendingMember, checkedPendingItems: checkedPendingItems }, () => {
            this.state.checkedPendingItems.size === this.state.pendingContacts.length ? this.setState({ checkedAll: true }) : this.setState({ checkedAll: false })
        });
    }

    /**** resend pending all ***/
    resendPendingAllInvite = () => {
        this.setState({ resendAllInviteVisibility: true });
    }

    resendPendingInvite = (index) => {
        this.setState({ resendInviteVisibility: true, editPendingUser: this.state.pendingContacts[index] });
    }

    editPendingInviteUser = (index, roleId) => {
        this.setState({ editPendingUser: this.state.pendingContacts[index] })
        if (roleId === 5) {
            this.toggleEditPendingModal(!this.state.editPendingModalVisibility);
        }
        else {
            this.toggleEditPendingInstructorModal(!this.state.editInstructorModalVisibility);
        }
    }

    toggleEditPendingInstructorModal = (visible) => {
        this.setState({ editInstructorModalVisibility: visible });
    }

    toggleEditPendingModal = (visible) => {
        this.setState({ editPendingModalVisibility: visible });
    }

    sortHandler = (column) => {
        const {sortCriteria, filterCriteria} = this.state;
        if(!sortCriteria.sortingInProcess){
            let newSortCriteria = sortCriteria;
            newSortCriteria.column = column;
            if(sortCriteria.column  == column){
                newSortCriteria.direction = (sortCriteria.direction== 'asc') ? 'desc' : 'asc';
            } else {
                newSortCriteria.direction = 'asc';
            }
            //newSortCriteria.sortingInProcess = true;
            let newFilterCriteria = {
                searchKeyword: '',
                teams: filterCriteria.teams,
                status: 1,
                role: filterCriteria.role,
                page: 1,
                pageSize: filterCriteria.pageSize,

            };
            this.setState({filteredContacts: [], sortCriteria: newSortCriteria, filterCriteria: newFilterCriteria}, this.getContactList);
        }
    }

    // createChatBtnHandler = () => {
    //     const {selectedMember, groupModal} = this.state;
    //     this.toggleGroupModal(!groupModal);
    // }

    btnClickHandler = (action, listType) => {
        const {selectedMember, selectedPendingMember, checkedPendingItems, groupModal} = this.state;
        if((listType == 'active' && selectedMember.size > 0) || (listType == 'pending' && selectedPendingMember.size > 0) ){
            switch(action) {
                case 'create-group':
                    this.toggleGroupModal(!groupModal);
                    break;
                case 'remove-members':
                    this.removeMembersFromTeam();
                    break;
                case 'resend-invites':
                    this.resendPendingAllInvite();
                    break;
                case 'delete-invites':
                    Helper.onRemovePendingInvite(checkedPendingItems, this);
                    break;
                default:
                    console.log(action);
            }
        } else {
            this.setState({  errorMessage: Enum.memberSelectionWarning, showError: true});
        }
    }
    /**
     * Function to filter contact list after activation and deactivation
     * @param uId
     * @param isUpdated
     */
    filterContactList = (uId, isUpdated) =>{
        console.log('isUpdated', isUpdated)
        let filteredContacts = this.state.filteredContacts;
        if(isUpdated){
            this.getContactList();
        }else{
            filteredContacts = filteredContacts.filter((x) => x.id !== uId);
            this.setState({ filteredContacts: filteredContacts})
        }
    }
    render() {
        const { serverValid, groupModal, selectedMember, loading, show, checkedAll, selectedTeam, guardianCount, filterCriteria, editPendingModalVisibility, editInstructorModalVisibility, resendInviteVisibility, resendAllInviteVisibility, selectedPendingMember, sortCriteria} = this.state;
        const isCoachOrAdminLogin = Helper.isCoachOrAdminLogin();
        let showBroadcastBtn = false;
        let selectedMemberTxt = '';
        if(this.state.pendingInviteFilter && selectedPendingMember.size > 0){
            selectedMemberTxt = selectedPendingMember.size == 1 ? `${selectedPendingMember.size} contact selected` : `${selectedPendingMember.size} contacts selected`;
        } else if(!this.state.pendingInviteFilter && selectedMember.size > 0){
            selectedMemberTxt = (selectedMember.size+guardianCount) == 1 ? `${selectedMember.size+guardianCount} contact selected` :  `${selectedMember.size+guardianCount} contacts selected`;
        }
        let removeMemberFromChatGroup = Helper.isCoachOrAdminLogin();
        return (
            <>
                <Loader loading={loading} />
                <div className="rgt-content">
                    <Row className="row--space">
                        <Col sm={12}>
                            {
                                this.state.showFields &&
                                <>
                                    {(this.state.activeTeamList !== null && this.state.activeTeamList.length <= 1) ?
                                    <>
                                        <h1>{Helper.teamName(selectedTeam)}</h1>
                                        <label className="selected-count">{selectedMemberTxt}</label>
                                    </>

                                        :
                                        <>
                                        <Dropdown className="dropdown-outer">
                                            <Dropdown.Toggle>
                                                <span className="dropdown-outer__content">{Helper.teamName(selectedTeam)}</span>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu >
                                                {(this.state.activeTeamList !== null && this.state.activeTeamList.length > 0) &&
                                                    this.state.activeTeamList.map((row, i) => {
                                                        return (
                                                            <Dropdown.Item key={i} onSelect={() => {
                                                                this.props.history.push(`/team-details/${row.id}`)
                                                            }}>
                                                                <div className="im-rounded-outer"><Image src={row.photo} alt={row.name} /></div><span className="dropdown-item__content">{row.name}</span>
                                                            </Dropdown.Item>
                                                        );
                                                    })
                                                }
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        <label className="selected-count">{selectedMemberTxt}</label>
                                    </>
                                    }
                                </>
                            }
                        </Col>
                        <Col md={12} lg={3} className="md--full-width order-md-3 order-lg-2">
                            <div className="info-box info-box--mrgn">
                                {this.state.showPhotoUploader ? <div className="smloader-min-height"><SmallLoader /></div> :
                                    <div className="info-box__header">
                                        {
                                            Helper.isCoachOrAdminLogin() &&
                                            <>
                                                <div className="info-box__uplaod">
                                                    <input type='file' accept=".jpg, .jpeg" onChange={this.handlePhotoChange} />
                                                    <div><Image src={Images.CameraIcRed} alt="camera" width="24" /></div>
                                                </div>
                                            </>
                                        }
                                        <Image src={selectedTeam.photo} alt={this.state.groupName} />
                                    </div>
                                }
                                {!this.state.updateTeamDivShow &&
                                    <div className="info-box__content">
                                        <h4>
                                            <span>{Helper.teamName(selectedTeam)}</span>
                                            {
                                                Helper.isCoachOrAdminLogin() &&
                                                <div className="add-linkbtn" onClick={this.onTeamEditClick}>
                                                    <Image className="edit-icon" src={Images.EditBlue} alt="Edit" />
                                                </div>
                                            }
                                        </h4>
                                        <p>{selectedTeam.description}</p>
                                    </div>
                                }
                                <div className="info-box__content" style={{ display: !this.state.updateTeamDivShow ? "none" : "" }}>
                                    <Formik
                                        ref={(ref) => this.formik = ref}
                                        enableReinitialize
                                        initialValues={{
                                            name: selectedTeam.name,
                                            description: (selectedTeam.description !== null && selectedTeam.description !== undefined) ? selectedTeam.description : "",
                                            location: selectedTeam.location ? selectedTeam.location : ""
                                        }}
                                        validationSchema={Yup.object().shape({
                                            name: Yup.string().trim()
                                                .max(50, TeamMessages.MAX_LENGTH_50)
                                                .required(TeamMessages.NAME_REQUIRED_ERROR),
                                            description: Yup.string().trim()
                                                .max(500, TeamMessages.MAX_LENGTH_500)
                                                .required(TeamMessages.DESC_REQUIRED_ERROR),
                                            location: Yup.string().trim()
                                                .max(50, TeamMessages.MAX_LENGTH_50)
                                        })}
                                        onSubmit={fields => {
                                            this.onTeamEditSubmit(fields);
                                        }}
                                        render={({ errors, status, touched }) => (
                                            <FormIkForm className="contact-us" noValidate>
                                                <div>
                                                    <div className="field form-group">
                                                        <Field name="name" type="text" id="name" className={'form-control' + (errors.name && touched.name  || serverValid.name !== '' ? ' is-invalid' : '')} onFocus={this.inputFocus} onBlur={this.inputBlur} maxLength="50"/>
                                                        <label htmlFor="name" className="form-label label-active">Name</label>
                                                        {
                                                            (errors.name && touched.name) ?
                                                                <ErrorMessage name="name" component="div" className="invalid-feedback" />
                                                                :
                                                                <div className="invalid-feedback">{serverValid.name}</div>
                                                        }
                                                    </div>
                                                    <div className="field form-group">
                                                        <Field name="description" type="text" id="description" className={'form-control' + (errors.description && touched.description || serverValid.description !== '' ? ' is-invalid' : '')} onFocus={this.inputFocus} onBlur={this.inputBlur} maxLength="500"/>
                                                        <label htmlFor="description" className="form-label label-active">Description</label>
                                                        {
                                                            (errors.description && touched.description) ?
                                                                <ErrorMessage name="description" component="div" className="invalid-feedback" />
                                                                :
                                                                <div className="invalid-feedback">{serverValid.description}</div>
                                                        }
                                                    </div>
                                                    <div className="field form-group">
                                                        <Field name="location" type="text" id="location" className={'form-control' + (errors.location && touched.location || serverValid.location !== '' ? ' is-invalid' : '')} onFocus={this.inputFocus} onBlur={this.inputBlur} maxLength="50"/>
                                                        <label htmlFor="location" className="form-label label-active">Location</label>
                                                        {
                                                            (errors.location && touched.location) ?
                                                                <ErrorMessage name="location" component="div" className="invalid-feedback" />
                                                                :
                                                                <div className="invalid-feedback">{serverValid.location}</div>
                                                        }                                                </div>
                                                    <div>
                                                        <Button type="Submit" className={`btn btn-medium d-inline-flex align-items-center mr-3 ${this.state.showTeamEditLoader ? 'btn-loader' : ''}`} disabled={this.state.showTeamEditLoader}>
                                                            <div className="btn__icon btn__icon--red"><Image src={Images.CompleteIc} alt="btn" /></div>
                                                            Save <span style={{ 'display': 'none' }} className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                        </Button>
                                                        <Button type="reset" className="btn-cancel ripple mr-0" onClick={this.onTeamEditClick} disabled={this.state.showTeamEditLoader}>Cancel</Button>
                                                    </div>
                                                </div>
                                            </FormIkForm>
                                        )}
                                    />
                                </div>
                            </div>
                            {
                                Helper.isCoachOrAdminLogin() &&
                                <div className="info-box info-box--space">
                                    <div className="info-box__content d-flex justify-content-between">
                                        <div className="icon-wrap">
                                            <Image src={Images.DashboardTotalStudent} width="38" alt="Members" />
                                        </div>
                                        <div className="info-box__content__desc">
                                            <p>Total Students</p>
                                            <div className="content__desc__heading">{selectedTeam.count}</div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </Col>
                        <Col md={12} lg={9} className="md--full-width order-md-2 order-lg-3">
                            <div className="block-pos block-pos--position justify-content-start">
                                <div className="block-pos__inner">
                                    {
                                        !this.state.pendingInviteFilter &&
                                        <OverlayTrigger placement="auto"
                                        overlay={
                                            <Tooltip id={`tooltip`}>
                                                Create SafeChat Group
                                            </Tooltip>}>
                                        <a href={null} onClick={() => {this.btnClickHandler('create-group', 'active')}}>
                                            <div className="outline-circle">
                                                <Image src={Images.createSafechat} alt="SafeChat Group" width="30" />
                                            </div>
                                        </a>
                                    </OverlayTrigger>
                                    }

                                    {
                                        Helper.isCoachOrAdminLogin() &&
                                        <>
                                            {
                                                (Helper.isInstructor() && !Helper.isAdminOrSubAdmin()) ? null :
                                                    <OverlayTrigger placement="auto"
                                                        overlay={
                                                            <Tooltip id={`tooltip`}>
                                                                Add Instructor
                                                             </Tooltip>}>
                                                        <Link to={`/teams/add-instructor/${this.state.teamId}`}>
                                                            <div className="outline-circle">
                                                                <Image src={Images.addInstructor} alt="Add member" width="30" />
                                                            </div>
                                                        </Link>
                                                    </OverlayTrigger>
                                            }
                                            <OverlayTrigger placement="auto"
                                                overlay={
                                                    <Tooltip id={`tooltip`}>
                                                        Add Contacts
                                                        </Tooltip>}>
                                                <Link to={`/teams/add-member/${this.state.teamId}/${this.state.selectedTeam.type}`}>
                                                    <div className="outline-circle">
                                                        <Image src={Images.AddContactRed} alt="Add member" width="30" />
                                                    </div>
                                                </Link>
                                            </OverlayTrigger>
                                           {
                                               Helper.isAdminOrSubAdmin() &&
                                                <OverlayTrigger placement="auto"
                                                  overlay={
                                                      <Tooltip id={`tooltip`}>
                                                          Bulk Upload Contacts
                                                      </Tooltip>}>
                                                  <a href={null} onClick={this.showBulkUpload}>
                                                      <div className="outline-circle">
                                                          <Image src={Images.uploadContacts} alt="Upload" width="30" />
                                                      </div>
                                                  </a>
                                               </OverlayTrigger>
                                           }
                                           {
                                               !this.state.pendingInviteFilter &&
                                                <OverlayTrigger placement="auto"
                                                    overlay={
                                                        <Tooltip id={`tooltip`}>
                                                            Remove Contacts
                                                        </Tooltip>}>
                                                    <a href={null} onClick={() => this.btnClickHandler('remove-members', 'active')}>
                                                        <div className="outline-circle">
                                                            <Image src={Images.Trash} alt="Delete" width="25"/>
                                                        </div>
                                                    </a>
                                                </OverlayTrigger>
                                           }
                                        </>
                                    }
                                    {
                                        (Helper.isCoachOrAdminLogin() && this.state.pendingInviteFilter) &&
                                        <>
                                            <OverlayTrigger placement="auto"
                                                overlay={
                                                    <Tooltip id={`tooltip`}>
                                                        Remove Contact(s)
                                                    </Tooltip>}>
                                                <a href={null} onClick={() => {this.btnClickHandler('delete-invites', 'pending')}}>
                                                    <div className="outline-circle">
                                                        <Image src={Images.Trash} alt="Delete" width="25"/>
                                                    </div>
                                                </a>
                                            </OverlayTrigger>
                                            <OverlayTrigger placement="auto"
                                                overlay={
                                                    <Tooltip id={`tooltip`}>
                                                    Resend
                                                    </Tooltip>}>
                                                <a href={null} onClick={() => {this.btnClickHandler('resend-invites', 'pending')}}>
                                                    <div className="outline-circle">
                                                        <Image src={Images.resendRed} alt="Resend" width="25" />
                                                    </div>
                                                </a>
                                            </OverlayTrigger>
                                        </>
                                    }
                                    { (selectedMember.size > 0 || selectedPendingMember.size > 0 ) &&
                                        <OverlayTrigger placement="auto"
                                                        overlay={
                                                            <Tooltip id={`tooltip`}>
                                                                Cancel
                                                            </Tooltip>}>
                                            <a href={null} onClick= {() => {
                                                this.deselectAll();
                                                this.deselectPendingAll();
                                            }}>
                                                <div className="outline-circle">
                                                    <Image src={Images.Cancel} alt="Cancel" width="25"/>
                                                </div>
                                            </a>
                                        </OverlayTrigger>
                                    }
                                </div>
                            </div>
                            <div className="widget widget--mrgn">
                                <div className={isCoachOrAdminLogin ? "tab-search tab-search--big" : "tab-search tab-search--big tab-search--widget"}>
                                    <Form className="form-inline" onSubmit={e => { e.preventDefault(); }}>
                                        <input type="text" name="focus" maxLength="100" placeholder="Search" className="form-control tab-search__box" onChange={(e) => this.doDebouncedSearchKeywordChange(e.target)} />
                                        <button className="close-icon" type="reset" onClick={(e) => this.doDebouncedSearchKeywordChange(e.target)}></button>
                                    </Form>
                                </div>
                                {isCoachOrAdminLogin &&
                                    <Tabs defaultActiveKey="0" onSelect={this.onRoleTabSelect}>
                                        <Tab eventKey="0" title="All">
                                            <ContactList data={this.state.filteredContacts}
                                                onCheckboxChange={(item) => { this.selectMember(item) }}
                                                checkedItems={this.state.checkedItems}
                                                onDeactivateContactClick={(item) => this.onDeactivateContactClick(item)}
                                                screen={"Member"}
                                                toggleLoader={this.toggleLoader}
                                                checkedAll={checkedAll}
                                                handleAllChecked={() => this.handleAllChecked()}
                                                fiterByStatus={"1"}
                                                sortCriteria={sortCriteria}
                                                sortHandler={this.sortHandler}
                                                allProps={this.props}
                                                filterContactList={(id, isUpdated)=>Helper.filterContactList(id, isUpdated, this)}
                                            />
                                        </Tab>
                                        <Tab eventKey={Enum.UserRoles.ATHLETE} title={Helper.memberRole([Enum.UserRoles.ATHLETE])}>
                                            <ContactList data={this.state.filteredContacts}
                                                onCheckboxChange={(item) => { this.selectMember(item) }}
                                                checkedItems={this.state.checkedItems}
                                                onDeactivateContactClick={(item) => this.onDeactivateContactClick(item)}
                                                screen={"Member"}
                                                toggleLoader={this.toggleLoader}
                                                checkedAll={checkedAll}
                                                handleAllChecked={() => this.handleAllChecked()}
                                                fiterByStatus={"1"}
                                                sortCriteria={sortCriteria}
                                                sortHandler={this.sortHandler}
                                                allProps={this.props}
                                                filterContactList={(id, isUpdated)=>Helper.filterContactList(id, isUpdated, this)}
                                            />

                                        </Tab>
                                        <Tab eventKey={Enum.UserRoles.COACH} title={Helper.memberRole([Enum.UserRoles.COACH])}>
                                            <ContactList data={this.state.filteredContacts} onCheckboxChange={(item) => { this.selectMember(item) }}
                                                checkedItems={this.state.checkedItems}
                                                onDeactivateContactClick={(item) => this.onDeactivateContactClick(item)}
                                                screen={"Member"}
                                                selectedTab={Enum.UserRoles.COACH}
                                                checkedAll={checkedAll}
                                                toggleLoader={this.toggleLoader}
                                                fiterByStatus={"1"}
                                                sortCriteria={sortCriteria}
                                                sortHandler={this.sortHandler}
                                                allProps={this.props}
                                                handleAllChecked={() => this.handleAllChecked()} />
                                        </Tab>
                                        <Tab eventKey="pendingInvites" title="Pending Invites">
                                            <PendingContactList
                                                data={this.state.pendingContacts}
                                                selectPendingMembers={(item) => this.selectPendingMembers(item)}
                                                checkedPendingItems={this.state.checkedPendingItems}
                                                editPendingInviteUser={(index, roleId) => this.editPendingInviteUser(index, roleId)}
                                                resendPendingInvite={(index) => this.resendPendingInvite(index)}
                                                onRemovePendingInviteClick={(item) => Helper.onRemovePendingInvite(item, this)}
                                                checkedAll={checkedAll}
                                                sortCriteria={sortCriteria}
                                                sortHandler={this.sortHandler}
                                                handlePendingAllChecked={this.handlePendingAllChecked}
                                                fiterByStatus={filterCriteria.status}
                                                screen={"Member"}
                                                copyInviteLink={(item)=>Helper.copyInviteLink(item, this)}
                                                copyInviteId={this.state.copyInviteId}
                                            />
                                        </Tab>
                                    </Tabs>
                                }
                                {
                                    !isCoachOrAdminLogin &&
                                    <ContactList data={this.state.filteredContacts}
                                        onCheckboxChange={(item) => { this.selectMember(item) }}
                                        checkedItems={this.state.checkedItems}
                                        onDeactivateContactClick={(id, status) => this.onDeactivateContactClick(id, status)}
                                        screen={"Member"}
                                        checkedAll={checkedAll}
                                        toggleLoader={this.toggleLoader}
                                        fiterByStatus={"1"}
                                        handleAllChecked={() => this.handleAllChecked()}
                                        allProps={this.props}
                                    />
                                }
                                {!this.state.recordLoaded && <SmallLoader />}
                                {(this.state.recordLoaded && (
                                    (this.state.filteredContacts.length === 0 && !this.state.pendingInviteFilter)
                                    || (this.state.pendingContacts.length === 0 && this.state.pendingInviteFilter)
                                )) && <NoRecord />}
                            </div>
                        </Col>
                    </Row>
                </div>
                {
                    // (selectedPendingMember.size > 0 || selectedMember.size > 0) &&
                    // <FixedActionBar
                    //     checkedPendingItems={this.state.checkedPendingItems}
                        // removeFromChatGroup={removeMemberFromChatGroup}
                        // removeFromChatGroupHandler={this.removeMembersFromTeam}
                        // createChatBtnHandler = {this.toggleGroupModal}
                        // resendBtnHandler = {this.resendPendingAllInvite}
                        // deletePendingInvitesHandler = {Helper.onRemovePendingInvite}
                        // cancelBtnHandler = {this.deselectAll}
                        // resendCancelBtnHandler = {this.deselectPendingAll}
                        // selectedMemberTxt = {selectedMemberTxt}
                        // showBroadcastBtn = {showBroadcastBtn}
                        // showPendingInvitesBtn = {(this.state.pendingInviteFilter && selectedPendingMember.size > 0) ? true : false}
                        // showcreateChatBtn = {(!this.state.pendingInviteFilter && selectedMember.size > 0) ? true : false}
                        // groupModal = {groupModal}
                        // disabled={this.state.disabled}
                        // mainObj = {this}
                    // />
                }
                <CreateChatGroup
                    selectedMember={selectedMember}
                    groupModal={groupModal}
                    toggleGroupModal={this.toggleGroupModal}
                    deleteSelectedMember={this.deleteSelectedMember}
                    history={this.props.history}
                    allProps={this.props}
                />
                <SuccessModal
                    successConfirmShow={this.state.successConfirmShow}
                    onHide={this.onSuccessCancel}
                    onConfirmClick={this.onSuccessConfirmClick}
                    successText={this.state.successText}
                    successButtonText={"OK"}
                />
                {editPendingModalVisibility &&
                    <EditPendingLG
                        modalVisibility={editPendingModalVisibility}
                        toggleModal={this.toggleEditPendingModal}
                        data={this.state.editPendingUser}
                        getPendingContacts={() => this.getPendingContacts()}
                    />
                }
                {editInstructorModalVisibility &&
                    <EditPendingInstructor
                        modalVisibility={editInstructorModalVisibility}
                        toggleModal={this.toggleEditPendingInstructorModal}
                        data={this.state.editPendingUser}
                        getPendingContacts={() => this.getPendingContacts()}
                    />
                }

                {resendInviteVisibility &&
                    <ResendInvite
                        closeInvite={() => {
                            this.setState({
                                resendInviteVisibility: false
                            })
                        }}
                        data={this.state.editPendingUser}
                        getPendingContacts={() => this.getPendingContacts()}
                    />
                }
                {resendAllInviteVisibility &&
                    <ResendAllInvite
                        closeInvite={() => {
                            this.setState({
                                resendAllInviteVisibility: false,
                                selectedPendingMember: new Map(),
                                checkedPendingItems: new Map(),
                                checkedAll: false
                            })
                        }}
                        data={this.state.selectedPendingMember}
                    />
                }
                <DeleteConfirmModal
                    deleteConfirmShow={this.state.updateContact.deleteConfirmShow}
                    onHide={this.onDeactivateContactConfirmCancel}
                    onCancelClick={this.onDeactivateContactCancelClick}
                    onConfirmClick={this.onDeactivateContactConfirmClick}
                    confirmText={this.state.updateContact.confirmText}
                    confirmButtonText={this.state.updateContact.confirmButtonText}
                    confirmType={this.state.updateContact.confirmTypeStatus}
                    modalLoader={this.state.modalLoader}
                />
                <ErrorModal
                    show={this.state.showError}
                    message={this.state.errorMessage}
                    onConfirmClick={() => { this.setState({ showError: false }) }}
                />
                <ErrorAccessDeniedModal
                    show={this.state.showAccessDeniedError}
                    message={this.state.errorMessage}
                    currentProps={this.props}
                />
                <AddMemberToTeam
                    selectedMember={selectedMember}
                    memberModal={{ show, team_id: this.state.teamId }}
                    toggleMemberModal={this.toggleMemberModal}
                    deleteSelectedMember={this.deleteSelectedMember}
                    redirect={this.props.history}
                    deleteMembers={true}
                />
            </>
        )
    }
}

export { TeamDetail };
