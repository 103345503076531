/**
 * Start - React
 * Name and Version: react, 16.9.0
 * Download link: https://www.npmjs.com/package/react
 */
import React, { Component } from 'react';
/* End - React */

/**
 * Start - React Router Dom
 * Name and Version: react-router-dom, 5.0.1
 * Download link: https://www.npmjs.com/package/react-router-dom
 */
import {Link} from 'react-router-dom';
/* End - React Router Dom */

/**
 * Start - axios
 * Name and Version: axios, 0.19.0
 * Download link: https://www.npmjs.com/package/axios
 */
import axios from 'axios';
/* End - axios */

/**
 * Start - Lodash.debounce
 * Name and Version: lodash.debounce, 4.0.8
 * Download link: https://www.npmjs.com/package/lodash.debounce
 */
import debounce from 'lodash.debounce';
/* End - Lodash.debounce */

/**
 * Start - React Bootstrap
 * Name and Version: react-bootstrap, 1.0.0-beta.12
 * Download link: https://www.npmjs.com/package/react-bootstrap
 */
import { Form, Row, Col, Image, Breadcrumb, Button, Tab, Tabs, OverlayTrigger, Tooltip } from 'react-bootstrap';
/* End - React Bootstrap */

import * as Images from '../../components/common/images';
import * as Helper from '../../components/common/functions';
import { Messages } from '../../components/common/message';
import { ContactList } from '../../components/contact/contact-list';
import * as Storage from '../../components/common/storage';
import * as Enum from '../../components/common/enum';
import AddMemberToGroup from './add-member-to-group';
import { NoRecord } from '../../components/no-record/no-record';
import { ErrorModal, ErrorAccessDeniedModal } from '../../components/modal/modalbox';
import { SmallLoader } from '../../components/small-loader/sm-loader';
import './group.scss';

class addGroupMember extends Component {
    constructor(props) {
        super(props);
      
        this.state = {
            selectedGroup: { name: '', description: '' },
            show: false,
            showError: false,
            errorMessage: null,
            showAccessDeniedError: null,
            accessMessage: null,
            groupId: props.match.params.group_id,
            totalMembers: this.props.match.params.total_members,
            members: null,
            userInfo: Storage.Get('user'),
            cancelToken: axios.CancelToken,
            cancel: undefined,
            disabled:false,
            filterCriteria: {
                searchKeyword: '',
                teams: [],
                status: 1,
                role: null,
                page: 1,
                pageSize: 150
            },
            checkedItems: new Map(),
            selectedMember: new Map(),
            recordLoaded: false,
            totalRecords: null,
            isActive: false
        };

        /* Using Debounce to prevent repeated events
         Windows scroll event debounce */
        this.doDebouncedTableScroll = debounce(this.onTableScroll, 100);
        /*  Filter Criteria Debounce */
        let filterDelay = 500;
        this.doDebouncedSearchKeywordChange = debounce(this.onSearchKeywordChange, filterDelay);
    }

    componentDidMount() {
        window.addEventListener('scroll', this.doDebouncedTableScroll);
        this.getGroupInfo();
        this.getContactList();
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.doDebouncedTableScroll);
    }

    onTableScroll = () => {
        // Don't proceed if:
        // old records are already loading
        // there's nothing left to load
        if (!this.state.recordLoaded || this.state.totalRecords === this.state.filteredContacts.length) return;

        // Get document.documentElement.scrollTop for all browsers
        const docTop = typeof window.pageYOffset != 'undefined' ? window.pageYOffset : document.documentElement.scrollTop ? document.documentElement.scrollTop : document.body.scrollTop ? document.body.scrollTop : 0;

        // // Reduce 20percent from the offsetHeight
        const reduceOffset = document.documentElement.offsetHeight - (20 / 100) * document.documentElement.offsetHeight;

        // Check if scroll has hit bottom
        if (window.innerHeight + docTop >= reduceOffset) {
            let newFilterCriteria = this.state.filterCriteria;
            newFilterCriteria.page = newFilterCriteria.page + 1;
            this.setState({ filterCriteria: newFilterCriteria });
            this.getContactList();
        }
    }

    getGroupInfo() {
        Helper.axiosInstance().get(`group/detail/${this.state.groupId}`)
            .then((response) => {
                if (response) {
                    this.setState({ selectedGroup: response.data.group, members: response.data.members });
                }
            })
            .catch((error) => {
                if (error.response.status === 404 && error.response.data !== undefined) {
                    this.setState({ accessMessage: error.response.data.error, showAccessDeniedError: true });
                } else {
                    this.setState({ errorMessage: Messages.SERVER_ERROR, showError: true, });
                }
            });
    }

    deleteSelectedMember = (id) => {
        let selectedMember = this.state.selectedMember,
            checkedItems = this.state.checkedItems;
        selectedMember.delete(id);
        checkedItems.delete(id);
        const guardianCount = Helper.guardianCount(this.state.selectedMember);
        this.setState({
            selectedMember,
            checkedItems,
            guardianCount
        });
        if (selectedMember.size === 0) {
            this.toggleMemberModal(false);
        }
    }

    getContactList = () => {
        this.setState({ recordLoaded: false });
        const selectedClubId = this.state.userInfo.selectedClubId,
            filterCriteria = this.state.filterCriteria;
        if (selectedClubId != null) {
            let cancel = this.state.cancel;
            let cancelToken = this.state.cancelToken;
            if (this.state.cancel !== undefined) {
                cancel();
            }
            let me = this;
            Helper.axiosInstance()
                .post(`contacts`
                    , JSON.stringify({
                        club_id: selectedClubId,
                        keyword: filterCriteria.searchKeyword,
                        team_id: [],
                        role_id: filterCriteria.role,
                        group_id: this.state.groupId,
                        status: filterCriteria.status,
                        page_size: filterCriteria.pageSize,
                        page: filterCriteria.page,
                        exclude_me: true,
                    }), {
                    cancelToken: new cancelToken(function executor(c) {
                        me.setState({ cancel: c });
                    })
                })
                .then((response) => {
                    if (response.data.success === true) {
                        if (this.state.filterCriteria.page === 1) {
                            this.setState({
                                filteredContacts: response.data.members
                            });
                        }
                        else {
                            let existingRecords = this.state.filteredContacts;
                            this.setState({ filteredContacts: existingRecords.concat(response.data.members) });
                        }
                        this.setState({ recordLoaded: true, totalRecords: response.data.count, });
                    }
                })
                .catch((error) => {
                    this.setState({ errorMessage: Messages.SERVER_ERROR, showError: true, });
                });
        }
    }

    toggleMemberModal = (visible) => {
        let selectedMember = this.state.selectedMember;
        let isChatGroup = true;
        const guardianCount = Helper.guardianCount(selectedMember, isChatGroup);
        if(parseInt(this.state.totalMembers)+parseInt(this.state.selectedMember.size)+parseInt(guardianCount) >process.env.REACT_APP_GROUP_LIMIT) {
            this.setState({  errorMessage: `SafeChat group should be limited to ${process.env.REACT_APP_GROUP_LIMIT} members including students, instructors, and guardians. Please note, all legal guardians of a student are added to the SafeChat group.`, showError: true, disabled: true })
        }else{
            this.setState({show: visible, disabled: false, showError: false})  
        }
       // this.setState({ show: visible });
    }

    onSearchKeywordChange = (target) => {
        let newFilterCriteria = this.state.filterCriteria;
        newFilterCriteria.searchKeyword = target.value.toLowerCase();
        this.reloadContacts(newFilterCriteria);
    }

    reloadContacts = (newFilterCriteria) => {
        newFilterCriteria.page = 1;
        this.setState({ filterCriteria: newFilterCriteria, filteredContacts: [], recordLoaded: false },
            () => {
                this.getContactList();
            });
    }

    handleCheckBoxChange = (item) => {
            let isChatGroup = true;
            let memberDetail = Helper.selectMember(item, this.state.selectedMember, this.state.checkedItems)
            const guardianCount = Helper.guardianCount(this.state.selectedMember, isChatGroup);
            this.setState({ memberDetail, guardianCount}, () => {
            if(parseInt(this.state.totalMembers)+parseInt(this.state.selectedMember.size)+parseInt(guardianCount) <= process.env.REACT_APP_GROUP_LIMIT) {
                    this.setState({ disabled: false, showError: false}) 
                 }
           
        });
    }

    onRoleTabSelect = (key) => {
        let newFilterCriteria = this.state.filterCriteria;
        newFilterCriteria.role = key === "0" ? null : key;
        this.reloadContacts(newFilterCriteria);
    }

    addContactsBtnHandler = () => {
        const {selectedMember, show} = this.state;
        if(selectedMember.size > 0){
            this.toggleMemberModal(!show);
        } else {
            this.setState({  errorMessage: Enum.memberSelectionWarning, showError: true});
        }
    }

    render() {
       
        const { show, selectedMember, totalRecords, selectedGroup, groupId, groupName, totalMembers, members, filterCriteria } = this.state;

        let includeLoggedInUser = false;    // add logged in member count
        let membersLength = Helper.fetchUniqueMembersCount(selectedMember, includeLoggedInUser, members);
        return (
            <>
                <div className="rgt-content">
                    <Row className="row--space">
                        <Col sm={12}>
                            <h1>{selectedGroup.name}</h1>
                            <Breadcrumb>                               
                                <li className="breadcrumb-item">
                                    <Link to={`/group-detail/${groupId}`}>{selectedGroup.name}</Link>
                                </li>
                                <Breadcrumb.Item active>Add Contacts</Breadcrumb.Item>
                            </Breadcrumb>
                            { 
                                selectedMember.size > 0 &&
                                <label className="selected-count">{membersLength} {membersLength == 1 ? " contact selected" : " contacts selected"}</label>
                            }
                        </Col>
                        <Col md={12} lg={3} className="md--full-width order-md-3 order-lg-2">
                            <div className="info-box mt-4">
                                <div className="info-box__header">
                                    <Image src={selectedGroup.photo} alt={groupName} />
                                </div>
                                <div className="info-box__content">
                                    <h4><span>{selectedGroup.name}</span></h4>
                                    <p>{selectedGroup.description}</p>
                                </div>
                            </div>
                            <div className="info-box info-box--space">
                                <div className="info-box__content d-flex justify-content-between">
                                    <div className="icon-wrap">
                                        <Image src={Images.DashboardTotalMember} alt="Members" width="38"/>
                                    </div>
                                    <div className="info-box__content__desc">
                                        <p>Total Contacts</p>
                                        <div className="content__desc__heading">{totalMembers}</div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col md={12} lg={9} className="md--full-width order-md-2 order-lg-3">
                            <div className="block-pos block-pos--position block-pos--position-top">                              
                                <OverlayTrigger placement="auto"
                                overlay={
                                    <Tooltip id={`tooltip`}>
                                        Add Contact
                                    </Tooltip>}>
                                <a href={null} onClick={() => {this.addContactsBtnHandler()}}>   
                                    <div className="outline-circle">
                                        <Image src={Images.AddContactRed} alt="Upload" width="30" />
                                    </div>
                                </a>
                                </OverlayTrigger>
                                <OverlayTrigger placement="auto"
                                    overlay={
                                        <Tooltip id={`tooltip`}>
                                        Cancel
                                    </Tooltip>}>
                                    <Link to={`/group-detail/${groupId}`}>
                                        <div className="outline-circle">
                                            <Image src={Images.Cancel} alt="Cancel" width="25" />
                                        </div>
                                    </Link>
                                </OverlayTrigger>
                            </div>
                            <div className="widget">
                                <div className="widget__heading">
                                    <h3>Add Contacts</h3>
                                </div>
                                <Row>
                                    <Col sm={6}>
                                        <div className="subheading-group">
                                            <div className="subheading">Contacts <span>{totalRecords} Total</span></div>
                                         
                                        </div>
                                    </Col>
                                    <Col sm={6}>
                                        <div className="tab-search tab-search--big tab-search--widget pt-0">
                                            <Form inline>
                                                <input type="text" name="focus" maxLength="100" required placeholder="Search" className="form-control tab-search__box" onChange={(e) => this.doDebouncedSearchKeywordChange(e.target)} />
                                                <button className="close-icon" type="reset" onClick={(e) => this.doDebouncedSearchKeywordChange(e.target)}></button>
                                            </Form>
                                        </div>
                                    </Col>
                                </Row>
                                <Tabs defaultActiveKey={0} onSelect={this.onRoleTabSelect}>
                                    <Tab eventKey="0" title="All">
                                        <ContactList
                                            data={this.state.filteredContacts}
                                            checkedItems={this.state.checkedItems}
                                            screen={"Add Member"}
                                            fiterByStatus={filterCriteria.status}
                                            onCheckboxChange={(item) => {this.handleCheckBoxChange(item)}}
                                            toggleLoader={this.toggleLoader}
                                            filterContactList={(id, isUpdated)=>Helper.filterContactList(id, isUpdated, this)}
                                            getContactList={this.getContactList}
                                        />
                                    </Tab>
                                    {
                                        (Helper.isInstructor() || ( !Helper.isLegalGuardian()) && ! Helper.isKidLogin()) &&
                                            <Tab eventKey={Enum.UserRoles.ATHLETE} title={Helper.memberRole([Enum.UserRoles.ATHLETE])}>
                                            <ContactList
                                                data={this.state.filteredContacts}
                                                checkedItems={this.state.checkedItems}
                                                screen={"Add Member"}
                                                fiterByStatus={filterCriteria.status}
                                                onCheckboxChange={(item) => { this.handleCheckBoxChange(item) }}
                                                toggleLoader={this.toggleLoader}
                                                filterContactList={(id, isUpdated)=>Helper.filterContactList(id, isUpdated, this)}
                                                getContactList={this.getContactList}
                                            />
                                        </Tab>
                                    }
                                   
                                    {
                                        (Helper.isInstructor() || ( !Helper.isLegalGuardian()) && ! Helper.isKidLogin()) &&
                                        <Tab eventKey={Enum.UserRoles.LEGAL_GUARDIAN} title={Helper.memberRole([Enum.UserRoles.LEGAL_GUARDIAN])}>
                                            <ContactList
                                                data={this.state.filteredContacts}
                                                checkedItems={this.state.checkedItems}
                                                screen={"Add Member"}
                                                fiterByStatus={filterCriteria.status}
                                                onCheckboxChange={(item) => { this.handleCheckBoxChange(item) }}
                                                toggleLoader={this.toggleLoader}
                                            />
                                        </Tab>
                                    }
                                    <Tab eventKey={Enum.UserRoles.COACH} title={Helper.memberRole([Enum.UserRoles.COACH])}>
                                        <ContactList
                                            data={this.state.filteredContacts}
                                            checkedItems={this.state.checkedItems}
                                            screen={"Add Member"}
                                            fiterByStatus={filterCriteria.status}
                                            onCheckboxChange={(item) => { this.handleCheckBoxChange(item) }}
                                            toggleLoader={this.toggleLoader}
                                        />
                                    </Tab>
                                </Tabs>

                                {!this.state.recordLoaded && <SmallLoader />}
                                {(this.state.recordLoaded && this.state.filteredContacts.length === 0) && <NoRecord />}
                                {
                                    // selectedMember.size > 0 &&
                                    // <div className="fixed-actionbar wgt-btnwrap">
                                    //     <span className="fixed-actionbar__count">{membersLength} {membersLength == 1 ? " contact selected" : " contacts selected"}</span>
                                    //     <div>
                                       
                                    //         <Button disabled={ (selectedMember.size  > 0 || this.state.disabled =='false') ? this.state.disabled : this.state.disabled} className="ripple btn btn-primary btn-medium d-inline-flex btn-rounded align-items-center" onClick={() => this.toggleMemberModal(!show)} >
                                    //         <div className="btn__icon btn__icon--red"><Image src={Images.CompleteIc} alt="Complete" /> </div>
                                    //             Add Contact
                                    //         </Button>
                                    //         <Link to={`/group-detail/${groupId}`} className="ripple btn btn-rounded btn-cancel d-inline-flex align-items-center justify-content-center ml-3">Cancel</Link>
                                    //     </div>
                                    // </div>
                                } 
                            </div>
                        </Col>
                    </Row>
                </div>
               
                <AddMemberToGroup
                    selectedMember={selectedMember}
                    memberModal={{ show, group_id: groupId }}
                    toggleMemberModal={this.toggleMemberModal}
                    deleteSelectedMember={this.deleteSelectedMember}
                    redirect={this.props.history}
                    selectedGroupChatId={selectedGroup.chat_group_id}
                    totalGroupMembers={totalMembers}
                />
                <ErrorModal
                    show={this.state.showError}
                    message={this.state.errorMessage}
                    onConfirmClick={() => { this.setState({ showError: false }) }}
                />
                <ErrorAccessDeniedModal
                    show={this.state.showAccessDeniedError}
                    message={this.state.accessMessage}
                    currentProps={this.props}
                />
            </>
        )
    }
}
export { addGroupMember };