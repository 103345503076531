import { userInfo } from 'os';

export const EditIc = require('../../assets/images/edit.svg').default;
export const ChatIc = require('../../assets/images/chat.svg').default;
export const DeleteIc = require('../../assets/images/delete.svg').default;
export const EditBlue =  require('../../assets/images/edit-blue.svg').default;
export const CameraIc =  require( '../../assets/images/cam-ic.svg').default;
export const TeamMember =  require( '../../assets/images/members.svg').default;
export const AddMemberIc =  require( '../../assets/images/btn-addmember-ic.svg').default;
export const DeleteWhite =  require( '../../assets/images/delete-white.svg').default;
export const uploadIc = require ('../../assets/images/upload-ic.svg').default;
export const uploadIm = require ('../../assets/images/upload.svg').default;
export const GroupModalIc =  require( '../../assets/images/group-popup-icon.svg').default;
export const CompleteIc = require('../../assets/images/complete-ic.svg').default;
export const AlertSuccess = require ('../../assets/images/alert-success.svg').default;
export const AlertWarning = require ('../../assets/images/alert-warning.svg').default;
export const UploadWhite = require ('../../assets/images/uploadwhitesm.svg').default;
export const SendIc = require ('../../assets/images/send-ic.svg').default;
export const sendSidebarIc = require ('../../assets/images/send-sidebar-ic.svg').default;
export const warningIc = require ('../../assets/images/warning.svg').default;
export const successIc = require ('../../assets/images/success-contact.svg').default;
export const PlusIc = require ('../../assets/images/plud-ic.svg').default;
export const PlusIcon = require('../../assets/images/plus-icon.svg').default;
export const EditIcon = require('../../assets/images/edit-icon.svg').default;
export const DefaultLogo = require('../../assets/images/clubdefault-logo.png').default;
export const Confirm = require('../../assets/images/confirm-box.svg').default;
export const Success = require('../../assets/images/success.svg').default;
export const ConfirmActivate = require('../../assets/images/confirm-activate.svg').default;
export const ActivateIcon = require('../../assets/images/active-icon.svg').default;
export const TeamModalIc = require('../../assets/images/team-popup.svg').default;
export const MemberDelete = require('../../assets/images/member-delete.svg').default;
export const DashboardMemberGroup = require('../../assets/images/dashboard-membergroup.svg').default;
export const DashboardChatGroup = require('../../assets/images/dashboard-chatgroup.svg').default;
export const DashboardContact = require('../../assets/images/dashboard-contact.svg').default;
export const DashboardTotalMember = require('../../assets/images/dashboard-total-member.png').default;
export const DashboardTotalStudent = require('../../assets/images/dashboard-total-student.png').default;
export const DashboardNextInvoice = require('../../assets/images/dashboard-next-invoice.svg').default;
export const DashboardTotalInstructor = require('../../assets/images/dashboard-total-instructor.png').default;
export const SuccessTick = require('../../assets/images/success-tick.svg').default;
export const GoToTop = require('../../assets/images/goto-top.svg').default;
export const AddLg = require('../../assets/images/add-lg.png').default;
export const MultiDelete = require('../../assets/images/multi-delete.png').default;
export const AppLogo  = require('../../assets/images/app-logo.svg').default;
export const SafecommLogo  = require('../../assets/images/logo.png').default;
export const SafecommLoginLogo  = require('../../assets/images/logo-login.png').default;
export const PlayStore  = require('../../assets/images/play-store.png').default;
export const AppStore  = require('../../assets/images/app-store.png').default;
export const MemberGroup = require('../../assets/images/membergroup.svg').default;
export const Refresh = require('../../assets/images/refresh.svg').default;
export const RefreshWhite = require('../../assets/images/refresh-white.svg').default;
export const CloseWhite = require('../../assets/images/close-white.svg').default;
export const Approve = require('../../assets/images/approve.svg').default;
export const Reject = require('../../assets/images/reject.svg').default;
export const Dollor = require('../../assets/images/dollor.svg').default;
export const ArrowUp = require('../../assets/images/arrow_up.svg').default;
export const ArrowDown = require('../../assets/images/arrow_down.svg').default;
export const ResendMessageIc = require('../../assets/images/resend-message.svg').default;
export const ArrowSort = require('../../assets/images/sort.svg').default;
export const uploadIconRed = require('../../assets/images/upload-red.png').default;
export const AddIconRed = require('../../assets/images/create-group-red.png').default;
export const AnnouncementRed = require('../../assets/images/announcements-red.png').default;
export const SafechatRed = require('../../assets/images/safe-chats-red.png').default;
export const EditIcRed = require('../../assets/images/edit-icon-red.png').default;
export const AddContactRed = require('../../assets/images/add-contact.png').default;
export const GroupIcRed = require('../../assets/images/create-member-group.png').default;
export const AddMemberIcon = require('../../assets/images/Add-Contact-Icon.png').default;
export const UploadIconWhite = require('../../assets/images/upload-white.png').default;
export const CreateGroup = require('../../assets/images/Create-Group-white.png').default;
export const MemberGroupNew = require('../../assets/images/groups-merged.png').default;
export const SafeChatIc = require('../../assets/images/SafeChats-MERGED.png').default;
export const ContactsIc = require('../../assets/images/Contacts-MERGED.png').default;
export const AddContactIc = require('../../assets/images/Add-Contact-MERGED.png').default;
export const AnnouncementIc = require('../../assets/images/Accouncements-MERGED.png').default;
export const Renewals = require('../../assets/images/renewals.png').default;
export const PendingInvites = require('../../assets/images/pending-Invites.png').default;

export const AnnouncementIcWhite = require('../../assets/images/Announcements-white.png').default;
export const CameraIcRed = require('../../assets/images/Add-Photo-red.png').default;
/* sidebar */
export const DashboardIm  = require('../../assets/images/home-white.png').default;
export const TeamIm  = require('../../assets/images/group-white.png').default;
export const GroupsIm  = require('../../assets/images/safe-chats-white.png').default;
export const ContactIm  = require('../../assets/images/contact-white.png').default;
export const OrganizationsIm  = require('../../assets/images/organisation.svg').default;
export const SettingIm  = require('../../assets/images/settings-white.png').default;
export const DataReview = require('../../assets/images/data-review.svg').default;
export const DummyUser = require('../../assets/images/user.jpg').default;
export const markPaid = require('../../assets/images/mark-paid.svg').default;
export const BroadcastHistory = require('../../assets/images/Announcements-white.png').default;
export const Trash = require('../../assets/images/trash.png').default;
export const Cancel = require('../../assets/images/cancel.png').default;
export const resendRed = require('../../assets/images/resend-red.png').default;
export const createSafechat = require('../../assets/images/create-safechat.png').default;
export const editSafechat = require('../../assets/images/edit-safechat.png').default;
export const uploadContacts = require('../../assets/images/upload-contacts.png').default;
export const uploadInstructor = require('../../assets/images/upload-instructor.png').default;
export const addInstructor = require('../../assets/images/add-instructor.png').default;
export const sidebarAnnouncement = require('../../assets/images/announcement-list.svg').default;
export const inviteInstructor = require('../../assets/images/invite-instructor.png').default;
export const inviteContact = require('../../assets/images/invite-contact.png').default;
export const inviteClub = require('../../assets/images/club-invite.png').default;
export const inviteClubFull = require('../../assets/images/club-invite-full.png').default;
export const archiveGroup = require('../../assets/images/archive-group.png').default;
export const resetPassword = require('../../assets/images/resetpassword.svg').default;
export const moreActions = require('../../assets/images/more-actions.svg').default;
export const announcement = require('../../assets/images/announcement.svg').default;
export const SafeCommFooterLogo = require('../../assets/images/safecomm-footer-logo.svg').default;
export const bulkUploadSideBar = require('../../assets/images/bulk_uplod_sidebar.png').default;
export const bulkUploadInstSideBar = require('../../assets/images/bulk_upload_ins.png').default;
export const linkGrey = require('../../assets/images/link-grey.svg').default;
export const faq = require('../../assets/images/faq.svg').default;
export const link = require('../../assets/images/link.svg').default;
export const ISRSignup = require('../../assets/images/signup_isr.png').default;
export const ISRPageLogo = require('../../assets/images/isr_page_logo.png').default;
export const helpIcon = require('../../assets/images/help.svg').default;
export const isrIcon = require('../../assets/images/isr.svg').default;
export const arrowLogin = require('../../assets/images/arrow_login_icon.svg').default;
export const switchIcon = require('../../assets/images/switch-icon.svg').default;
