/**
 * Start - React
 * Name and Version: react, 16.9.0
 * Download link: https://www.npmjs.com/package/react
 */
import React, { Component } from 'react';
/* End - React */

/**
 * Start - React Router Dom
 * Name and Version: react-router-dom, 5.0.1
 * Download link: https://www.npmjs.com/package/react-router-dom
 */
import { Link } from 'react-router-dom';
/* End - React Router Dom */

/**
 * Start - React Bootstrap
 * Name and Version: react-bootstrap, 1.0.0-beta.12
 * Download link: https://www.npmjs.com/package/react-bootstrap
 */
import {Nav, Navbar, Image, Tooltip, OverlayTrigger} from 'react-bootstrap';
/* End - React Bootstrap */

import OrganizationsIm from '../../assets/images/organisation.svg';
import InvoiceIm from '../../assets/images/invoice.svg';
import * as Storage from '../../components/common/storage';
import * as Helper from '../../components/common/functions';
import * as Images from '../../components/common/images';
import './sidebar.scss';
import {SafeCommWebsite} from '../../components/common/enum';
import BroadcastMesaage from '../../components/common/broadcast-message';
import Loader from '../../components/loader/loader';
import { ErrorModal } from '../../components/modal/modalbox';
import {isISRLogin} from "../../components/common/functions";
import { marketingWebsiteUrl } from '../../components/common/enum'
import InviteClub from './../../scenes/isr/invite-club';
export default class Sidebar extends Component {
    constructor(props, context) {
        super(props, context);
        const userData = Storage.Get('user');
        this.menuArr = [
            ["/","menulist"],
            ["/organization","menulist"],
            ["/invoices","menulist"],
            ["/organization/review","menulist"],
            ["/teams","menulist"],
            ["/groups","menulist"],
            ["/contacts","menulist"],
            ["/broadcast-history","menulist"],
            ["broadcast-to-all","menulist"],
            ["/settings","menulist"],
            ["/bulk-upload","menulist"],
            ["/bulk-upload-instructors","menulist"],
            ["/isr","menulist"],
            ["/register-as-isr/","menulist"],
            ["/register-as-isr","menulist"],
            ["/commission-history","menulist"],
        ];
        if(userData && userData.selectedClubId){
            this.menuArr.push(["/edit-organization/"+userData.selectedClubId, "menulist"]);
        }
        this.state = {
            clicked: false,
            showMenu: true,
            activeMenu: new Map(this.menuArr),
            isSuperAdmin: false,
            selectedclubid: userData && userData.selectedClubId !== undefined ? userData.selectedClubId : '',
            selectedMembersCount: null,
            selectedMembers: 'all',
            showError: false,
            errorMessage: null,
            broadcastBtnLoader: false,
            broadcastModal:false,
            userInfo: Storage.Get('user'),
            loading: false,
            modalVisibility:false
        };
        this.handleActiveMenu = this.handleActiveMenu.bind(this);
    };

    componentDidMount = () => {
        let userInfo = Storage.Get('user');
        const isSuperAdmin = userInfo && userInfo.is_super_admin;
        this.setState({
            isSuperAdmin: isSuperAdmin
        });
        this.handleActiveMenu(this.props.location.pathname,'onload');
    }

    shouldComponentUpdate(nextProps){
        if(this.props.location.pathname !== nextProps.location.pathname){
            this.handleActiveMenu(nextProps.location.pathname);
        }
        return true;
    }

    handleActiveMenu(path, type) {
        let activeMenu = this.state.activeMenu;

        if(!type){
            this.menuArr.forEach((value) => {
                activeMenu.set(value[0], value[1]);
            });
        }
        activeMenu.set(path, "menulist active");
        this.setState({
            activeMenu
        });
    }

    toggleModal = (visible) => {
        this.setState({modalVisibility: visible});
    };

    render() {
        const {activeMenu, selectedMembersCount, selectedMembers, broadcastModal, loading, modalVisibility} = this.state,
            canReviewStudent = Storage.Get('user') && Storage.Get('user').can_review_kid,
            pathname = this.props.location.pathname,
            orgClass = (pathname === "/organization/review") ? activeMenu.get(pathname) : activeMenu.get("/organization");
        const isrClass = this.props.location.hash === "#info" ? '/register-as-isr/': '/register-as-isr'
        return (
            <aside id="js-sidebar-menu" className={"sidebar-wrap " + (this.state.showMenu ? 'hidden' : 'show')}>
                <div className="y-scroll">
                    <div className="sidebar-menu">
                        <Navbar bg="light" expand="lg">
                            <Navbar.Toggle aria-controls="basic-navbar-nav" />
                            <Navbar.Collapse id="basic-navbar-nav">
                                <Nav className="mr-auto">
                                    {
                                        Helper.isISRLogin() ?
                                            <>
                                                <h3>Manage</h3>
                                                <Link to={'/'} className={activeMenu.get("/")}>
                                                    <span className="sidebar-ic">
                                                        <Image src={OrganizationsIm} alt="Organizations" width="18"/>
                                                    </span>
                                                    <span>Organizations</span>
                                                </Link>
                                                <Link to="/commission-history" className={activeMenu.get("/commission-history")} onClick={() => this.handleActiveMenu("/commission-history")}><span className="sidebar-ic"><Image src={InvoiceIm} alt="Commission History" width="18" /></span><span>Commission History</span></Link>
                                                <a href={null} onClick={() => this.toggleModal(!modalVisibility)}>
                                                    <span className="sidebar-ic">
                                                        {/*<Image src={InvoiceIm} alt="Invite New Organization" width="18" />*/}
                                                          <Image src={Images.inviteClubFull} alt="Invite New Organization" width="18"/>
                                                    </span>
                                                    <span>Invite New Organization</span></a>
                                            </>
                                            :
                                            this.state.isSuperAdmin ?
                                            <>
                                                <Link to="/" className={activeMenu.get("/")} onClick={() => this.handleActiveMenu("/")}><Image src={Images.DashboardIm} alt="Dashboard" width="24" /><span>Dashboard</span></Link>
                                                <h3>Manage</h3>
                                                <Link to="/organization" className={activeMenu.get("/organization")} onClick={() => this.handleActiveMenu("/organization")}><span className="sidebar-ic"><Image src={OrganizationsIm} alt="Organizations" width="18" /></span><span>Organizations</span></Link>
                                                <Link to="/invoices" className={activeMenu.get("/invoices")} onClick={() => this.handleActiveMenu("/invoices")}><span className="sidebar-ic"><Image src={InvoiceIm} alt="Invoices" width="18" /></span><span>Invoices</span></Link>
                                                <Link to="/isr" className={activeMenu.get("/isr")} onClick={() => this.handleActiveMenu("/settings")}><span className="sidebar-ic"><Image src={Images.isrIcon} alt="Settings" width="18" /></span><span>Marketing Affiliates</span></Link>
                                                <Link to="/settings" className={activeMenu.get("/settings")} onClick={() => this.handleActiveMenu("/settings")}><span className="sidebar-ic"><Image src={Images.SettingIm} alt="Settings" width="18" /></span><span>Settings</span></Link>
                                                <Link to="/commission-history" className={activeMenu.get("/commission-history")} onClick={() => this.handleActiveMenu("/commission-history")}><span className="sidebar-ic"><Image src={InvoiceIm} alt="MA Commission History" width="18" /></span><span>MA Commission History</span></Link>
                                                <a onClick={()=>Helper.fetchSelectedMembersCount('admins', this, null)}><span className="sidebar-ic sidebar-ic--pos"><Image src={Images.BroadcastHistory} alt="Announcement To Org Admins" width="18" /></span><span>Announcement To Org Admins</span></a>
                                           </>
                                        :
                                            <>
                                                {
                                                    Helper.isCoachOrAdminLogin() &&
                                                    <Link to="/" className={activeMenu.get("/")} onClick={() => this.handleActiveMenu("/")}><Image src={Images.DashboardIm} alt="Dashboard" width="24" /><span>Home</span></Link>
                                                }
                                                <h3>Manage</h3>
                                                <Link to="/groups" className={activeMenu.get("/groups")} onClick={() => this.handleActiveMenu("/groups")}><Image src={Images.GroupsIm} alt="Chat Groups" width="21" /><span>SafeChats</span></Link>
                                                <Link to="/teams" className={activeMenu.get("/teams")} onClick={() => this.handleActiveMenu("/teams")}><Image src={Images.TeamIm} alt="Member Groups" width="24" /><span>Groups</span></Link>
                                                <Link to="/contacts" className={activeMenu.get("/contacts")} onClick={() => this.handleActiveMenu("/contacts")}><span className="sidebar-ic"><Image src={Images.ContactIm} alt="Contacts" width="20" /></span><span>Contacts</span></Link>
                                                {
                                                    Helper.isLegalGuardian() && canReviewStudent ?
                                                        <Link to={`/student-data`} className={activeMenu.get("/student-data")} onClick={() => this.handleActiveMenu(`/student-data`)}><span className="sidebar-ic"><Image src={Images.DataReview} alt="Student Data" width="21" /></span><span>Student's Data</span></Link>
                                                    :
                                                        null
                                                }

                                                {
                                                    Helper.isAdminOrSubAdmin() &&
                                                    <>
                                                        <Link to="/bulk-upload" className={activeMenu.get("/bulk-upload")} onClick={() => this.handleActiveMenu("/bulk-upload")}><span className="sidebar-ic"><Image src={Images.bulkUploadSideBar} alt="Bulk Upload Contacts" width="20"/></span><span>Bulk Upload Contacts</span></Link>
                                                        <Link to="/bulk-upload-instructors" className={activeMenu.get("/bulk-upload-instructors")} onClick={() => this.handleActiveMenu("/bulk-upload-instructors")}><span className="sidebar-ic"><Image src={Images.bulkUploadInstSideBar} alt="Bulk Upload Contacts" width="20"/></span><span>Bulk Upload Instructors</span></Link>
                                                    </>
                                                }
                                                { Helper.isCoachOrAdminLogin() &&
                                                        <Link to="/broadcast-history" className={activeMenu.get("/broadcast-history")} onClick={() => this.handleActiveMenu("/broadcast-history")}><span className="sidebar-ic"><Image src={Images.sidebarAnnouncement} alt="Announcements" width="20" /></span><span>Announcements</span></Link>
                                                }
                                                {
                                                    Helper.isAdminOrSubAdmin() &&
                                                        <Link to={`/edit-organization/${this.state.selectedclubid}`} className={activeMenu.get("/edit-organization/"+this.state.selectedclubid)} onClick={() => this.handleActiveMenu(`/edit-organization/${this.state.selectedclubid}`)}><span className="sidebar-ic sidebar-ic--pos"><Image src={Images.SettingIm} alt="Settings" width="18" /></span><span>Settings</span></Link>
                                                }
                                                {
                                                    Helper.isAdminLogin() &&
                                                        <Link to="/invoices" className={activeMenu.get("/invoices")} onClick={() => this.handleActiveMenu("/invoices")}><span className="sidebar-ic"><Image src={InvoiceIm} alt="Invoices" width="18" /></span><span>Invoices</span></Link>
                                                }
                                                {
                                                    Helper.isCoachOrAdminLogin() &&
                                                        <a onClick={() => Helper.fetchSelectedMembersCount(true, this, null)}><span className="sidebar-ic sidebar-ic--pos"><Image src={Images.BroadcastHistory} alt="Announcement To All" width="18" /></span><span>Announcement To All</span></a>
                                                }

                                                {
                                                    (Helper.isLegalGuardian() || Helper.isCoachOrAdminLogin()) && !Storage.Get('user').is_isr  ?
                                                    <Link to={`/register-as-isr/#info`} className={activeMenu.get(isrClass)} onClick={() => this.handleActiveMenu(`/register-as-isr`)}><span className="sidebar-ic sidebar-ic--pos"><Image src={Images.ISRSignup} alt="Register as Marketing Affiliate"  width="18" /></span><span>Register as Marketing Affiliate</span></Link>: null
                                                }
                                                <Link className="menulist" to={{ pathname: marketingWebsiteUrl }} target="_blank" ><span className="sidebar-ic"><Image src={Images.faq} alt="Member Groups" width="18" /></span><span>FAQ</span></Link>


                                            </>
                                    }
                                </Nav>
                            </Navbar.Collapse>
                        </Navbar>
                    </div>
                    <footer className="sidebar-footer">
                        <div className="sidebar-footer__inner">
                            <a href={SafeCommWebsite} target="_blank"><img width="170" src={Images.SafeCommFooterLogo} alt="SafeComm" /></a>
                            <ul>
                                <li><a target="_blank" href="/privacy-policy">Privacy Policy</a></li>
                                <li><a target="_blank" href="/terms-of-service">Terms of Service</a></li>
                            </ul>
                        </div>

                    </footer>
                </div>
                <InviteClub
                    modalVisibility={modalVisibility}
                    toggleModal={this.toggleModal}
                    history={this.props.history}
                    getPendingContacts={() => this.getPendingContacts()}
                />
                <BroadcastMesaage
                    selectedMembers={selectedMembers}
                    selectedMembersCount={selectedMembersCount}
                    broadcastModal={broadcastModal}
                    toggleBroadcastModal={Helper.toggleBroadcastModal}
                    deselectAll={this.deselectAll}
                    type = "contact"
                    mainObj = {this}
                />
                 <ErrorModal
                    show={this.state.showError}
                    message={this.state.errorMessage}
                    onConfirmClick={() => { this.setState({ showError: false }) }}
                ></ErrorModal>
                <Loader loading={loading} />
            </aside>
        );
    }
}
