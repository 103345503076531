/**
 * Start - React
 * Name and Version: react, 16.9.0
 * Download link: https://www.npmjs.com/package/react
 */
import React, { Component } from 'react';
/* End - React */

/**
 * Start - React Bootstrap
 * Name and Version: react-bootstrap, 1.0.0-beta.12
 * Download link: https://www.npmjs.com/package/react-bootstrap
 */
import { Image, Button } from 'react-bootstrap';
/* End - React Bootstrap */

import * as Images from './images';
import * as Helper from '../../components/common/functions';
export default class FixedActionBar extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        const {removeFromChatGroup, showPendingInvitesBtn, showBroadcastBtn, showcreateChatBtn, showAddToChatGroupBtn, broadcastBtnLoader, selectedMemberTxt, groupModal, type, mainObj, checkedPendingItems } = this.props;
        return (
            <>
                <div className="fixed-actionbar wgt-btnwrap fixed-actionbar--mrgn">
                    <span className="fixed-actionbar__count">{selectedMemberTxt}</span>
                    <div>
                        {
                            (typeof showPendingInvitesBtn == 'undefined' || !showPendingInvitesBtn) && typeof removeFromChatGroup != 'undefined' && removeFromChatGroup && 
                                <Button className="ripple btn-icon btn-icon--delete btn-rounded d-inline-flex align-items-center mr-3" onClick={this.props.removeFromChatGroupHandler}>
                                </Button>
                        }
                        {
                            typeof showPendingInvitesBtn != 'undefined' && showPendingInvitesBtn &&  (Helper.isAdminOrSubAdmin() || Helper.isInstructor()) &&
                                <Button className="ripple btn-icon btn-icon--delete btn-rounded d-inline-flex align-items-center mr-3" onClick={this.props.deletePendingInvitesHandler.bind(this, checkedPendingItems, mainObj)}>
                                </Button>
                        }
                        {  
                            typeof showBroadcastBtn != 'undefined' && showBroadcastBtn && 
                                <Button className={"ripple btn-medium btn-rounded d-inline-flex align-items-center mr-3 " + (broadcastBtnLoader ? "btn-loader" : "")} onClick={() => this.props.broadcastBtnHandler(false, mainObj, type)}  disabled={broadcastBtnLoader ? true : false} >
                                    <div className="btn__icon btn__icon--red"><Image src={Images.AnnouncementIcWhite} alt="Announcement Message" width="14" /></div>Send Announcement
                                    <span style={{ 'display': 'none' }} className="ml-2 spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                </Button>
                        }
                        {
                            typeof showcreateChatBtn != 'undefined' && showcreateChatBtn && 
                            <Button className="ripple btn-medium btn-rounded d-inline-flex align-items-center mr-3" onClick={() => this.props.createChatBtnHandler(!groupModal)} disabled={this.props.disabled} >
                            <div className="btn__icon btn__icon--red"><Image src={Images.EditIcon} alt="create group" /></div> Create SafeChat Group
                            </Button>
                        }

                        {
                            typeof showAddToChatGroupBtn != 'undefined' && showAddToChatGroupBtn && 
                            <Button className="ripple btn-medium btn-rounded d-inline-flex align-items-center mr-3" onClick={() => this.props.addToChatGroupBtnHandler()} disabled={this.props.disabled} >
                            <div className="btn__icon btn__icon--red"><Image src={Images.PlusIcon} alt="create group" /></div> {this.props.addToChatGroupBtnTxt}
                            </Button>
                        }
                        {    
                            typeof showPendingInvitesBtn != 'undefined' && showPendingInvitesBtn && 
                            <>
                                <Button className="ripple btn-medium btn-rounded d-inline-flex align-items-center mr-3" onClick={() => this.props.resendBtnHandler()}>
                                <div className="btn__icon btn__icon--red"><Image src={Images.RefreshWhite} alt="Resend" /></div> Resend
                                </Button>
                                <Button onClick={() => this.props.resendCancelBtnHandler()} className="ripple btn-cancel btn-rounded align-items-center text-center">
                                    Cancel
                                </Button>
                            </>
                        }
                        
                        {
                            ((typeof showBroadcastBtn != 'undefined' && showBroadcastBtn) || (typeof showcreateChatBtn != 'undefined' && showcreateChatBtn)) && 
                            <Button onClick={() => this.props.cancelBtnHandler()} className="ripple btn-cancel btn-rounded align-items-center text-center">
                                Cancel
                            </Button>
                        }
                    </div>
                </div>
            </>
        )
    }
}
